import React, {useState, useEffect } from "react";
import PostCompleteArt from "../components/PostCompleteArt";
import Bdexecute from "../components/Bdexecute";
import { useParams } from "react-router-dom";
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

export default function App() {
  let params = useParams();
  let [posts, setPosts] = useState([]);
  let [linkpagina, setLinkpag] = useState('p');
  
  useEffect(() => {
    const loadPosts = async () => {
      let results;

      if (params.id4) {
        results = await Bdexecute("GET", `api/posts/${params.id4}`);
      }
      setPosts(results);
      document.title = results.title;
    }
  
    loadPosts();
  }, [params]);
  

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();


  //breadcumb
let [itens, setItens] = useState([]);
useEffect(() => {
  const loadPostsMenu = async () => {
    let resultsmenu;
    resultsmenu = await Bdexecute("GET", `api/struct/`);
    setItens(resultsmenu);
  }

  loadPostsMenu();
}, []);

let [seccao, setSeccao] = useState();
let [menutop, setMenutop] = useState();
let [menupag, setMenupag] = useState();
useEffect(() => {
  if (!itens) { return }

  const findMenuByIdRecursive = (arr, targetId) => {
    for (const item of arr) {
      if (item.menuurl === targetId || item._id === targetId) {
        return item.menu;
      }
      if (item.submenu && Array.isArray(item.submenu)) {
        const menu = findMenuByIdRecursive(item.submenu, targetId);
        if (menu) {
          return menu;
        }
      }
    }
    return null;
  };

  const findMenuPrincipal = (arr, targetId) => {
    let link;
    let link2;
    
    for (const item of arr) {
      if (item.menuurl === targetId) {
        console.log(item.menuurl)
        link = item.principal === '1' ? 'pagina' : 'p';
        return link;
      }
    }
    for (const item of arr) {
      if (item._id === targetId) {
        link2 = item.principal === '1' ? 'pagina' : 'pag';
        return link2;
      }
    }
    return 'pagina';
  };
  setLinkpag(findMenuPrincipal(itens, params.id));

  setSeccao(findMenuByIdRecursive(itens, params.id));
  setMenutop(findMenuByIdRecursive(itens, params.id2));
  setMenupag(findMenuByIdRecursive(itens, params.id3));
}, [itens, params]);


  return (
    <React.Fragment>


    <div className="container-cinza">
      <div className="container">


      <Breadcrumb
      separator=">"
        items={[
          {
            title: <a href="/" alt={`Ligação para a página principal`}>Início</a>,
          },
          params.id && params.id !== 'undefined' && params.id.length > 2 ? {
            title: <a href={`/${linkpagina}/${params.id}`} alt={`Ligação para ${seccao}`}>{seccao}</a>,
          } : '',
          params.id2 && params.id2 !== 'undefined' && params.id2.length > 2 ? {
            title:  <a href={`/${linkpagina}/${params.id}/${params.id2}`} alt={`Ligação para ${menutop}`}>{menutop}</a>,
          } : '',
          params.id3 && params.id3 !== 'undefined' && params.id3.length > 2 ? {
            title: <a href={`/${linkpagina}/${params.id}/${params.id2}/${params.id3}`} alt={`Ligação para ${menupag}`}>{menupag}</a>,
          } : '',
          params.id4 && params.id4 !== 'undefined' && params.id4.length > 2 ? {
            title: posts.title,
          } : '',
        ]}
      />

      </div>
      </div>
      <div className="container">

      <div id="seccaoprincipal">
          {posts && posts.datapublicacao < formattedDate ? (
            <PostCompleteArt {...posts} key={posts._id} />
          ) : (
            <i>Carregando dados...</i>
          )
        }
      </div>

      <p className="center"><Link className='buttonSecundario' to="#" onClick={() => window.history.back()}>VOLTAR</Link></p>

      </div>
      
    </React.Fragment>
  )
}