import React from "react";
import Card from "@leafygreen-ui/card";
import { css } from "@leafygreen-ui/emotion";
import { H3 } from "@leafygreen-ui/typography";

const cardStyle = css`
border-radius: 0px !important;
  margin: 0.5em;
  min-height: 160px;
  box-shadow: none !important;

  &:hover {
    box-shadow: 0 4px 10px -4px rgba(0, 30, 43, 0.3) !important;
  }

`
export default function PostAgenda(props) {
  const optionsdate = { day: 'numeric',  month: 'long', year: 'numeric' };
  const optionshour = {hour: '2-digit', minute:'2-digit'};

  return (
    <a href={`/agenda/${props._id}`}>
    <Card className={cardStyle}>
      {props.cartaz &&
      <div style={{ float: 'left', width: '200px', height: '100px', margin: '10px', backgroundSize: 'cover', backgroundImage: `url('${props.cartaz ? props.cartaz.replace(/ /g, "%20") : ""}')` }}></div>
      }
      {props.evento && 
      <H3>{props.evento}</H3>
      }
      {(new Date(props.startDate)).toLocaleDateString('pt-PT', optionsdate)}  {(new Date(props.startDate)).toLocaleTimeString('pt-PT', optionshour)}
      <p className="direita">Consultar evento</p>
    </Card>
    </a>
  )
}