import {
  MenuOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { useState, useEffect  } from 'react';
import Bdexecute from "./Bdexecute";
import { RightOutlined } from '@ant-design/icons';
import { css } from "@leafygreen-ui/emotion";

const botaoHamburguer = css`
margin-left: 16px;
margin-bottom: 16px;
display: none;

@media (max-width:800px) {
  display: block;
}
`;

const extractArrays = (data, id) => {
  let urlp = `/pagina/${id}`;
  if (data && typeof data === 'object') {
  return Object.values(data).flatMap((value) => {
    if (Array.isArray(value)) {
      return value.map((item) => (item.oculto !== true && { 
        label: (
          Object.keys(item).includes('submenu') && item.submenu.length > 0
            ? item.menu
            : <a href={`${urlp}/${item._id}`}>{item.menu}</a>
        ),
        key: item._id,
        ...(Object.keys(item).includes('submenu') && item.submenu.length > 0
          ? { children: extractArrays(item, id+'/'+item._id) }
          : {})
      }));
    } else if (typeof value === 'object') {
      return extractArrays(value);
    }
    return [];
  })};
};

const MenuPrincipal = (props) => {

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const [current, setCurrent] = useState('1rw4373hd8s92k9eArs53WRfd4imbn38');
  const [mode, setMode] = useState("horizontal");
  let array1;

  if (props.id) {
    array1 = [{ label: (<a href="/">Início</a>), key: '1rw4373hd8s92k9eArs53WRfd4imbn38' }];
  } else {
    array1 = [];
  }

  let [itens, setItens] = useState([]);
  useEffect(() => {
    const loadPosts = async () => {
      let results;
      let results1;
  
      if (props.id && props.id!=='undefined') {
        results = await Bdexecute("GET", `api/struct/${props.id}`);
      } else {
        results1 = await Bdexecute("GET", `api/struct/`);
        results = results1.filter(item => item.principal === "1" && item.oculto !== true)[0];
      }
      setItens(results);
    }
  
    loadPosts();
  }, [props.id]);

  

  useEffect(() => {
    if (props.id2) {
      setCurrent(props.id2);
    }
  }, [props.id2]);


  const arraysOnly = extractArrays(itens, itens._id);

  let arrayMenu = array1.concat(arraysOnly);


  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const isMenuSticky = window.scrollY > 110; // Defina a condição para ativar o sticky menu

      setIsSticky(isMenuSticky);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (props.ori === 'vertical') {
        setMode("inline");
      } else if (screenWidth < 800) {
        setMode("vertical");
        setCollapsed(true);
      } else {
        setMode("horizontal");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Verificar a largura inicial ao carregar a página

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.ori]);


  const onClick = (e) => {
    /* console.log('click ', e); */
    setCurrent(e.key);
  };

/*   let areaMaiuscula = '';
  if (Area) {
    areaMaiuscula = Area.toUpperCase();
  } */

let menuItemsWithIcons = [];

if (props.ori === 'vertical') {
  menuItemsWithIcons = arrayMenu.map(item => {
    let icon = <RightOutlined />
    return {
      ...item,
      icon,
    };
  });
  
} else {
  menuItemsWithIcons = arrayMenu;
}

  return <div className='barra-menu'>
{/*     {props.tit !== '1' && (
      <div className={`subTitulo ${isSticky ? 'sticky' : ''}`}>
        <span>{areaMaiuscula}</span>
      </div>
    )} */}
      <Button type="primary" onClick={toggleCollapsed} className={botaoHamburguer}>
        {collapsed ? <MenuOutlined /> : <CloseOutlined />}
      </Button>

  <Menu className={`menu-principal ${isSticky ? 'sticky' : ''}`} onClick={onClick} selectedKeys={[current]} mode={mode} inlineCollapsed={collapsed} disabledOverflow={true} items={menuItemsWithIcons} />

  </div>;
};
export default MenuPrincipal;