import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Icon from '@leafygreen-ui/icon';

function ExcelTable(props) {
  const [excelData, setExcelData] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [nomeColuna1, setNomeColuna1] = useState(null);
  const [nomeColuna2, setNomeColuna2] = useState(null);
  const [nomeColuna3, setNomeColuna3] = useState(null); 
  const [nomeColuna1i, setNomeColuna1i] = useState(null);
  const [nomeColuna2i, setNomeColuna2i] = useState(null);
  const [nomeColuna3i, setNomeColuna3i] = useState(null);
  const mostrafiltros = props.filtro ? false : true;

  let [selectedValueColuna1, setSelectedValueColuna1] = useState('');
  let [selectedValueColuna2, setSelectedValueColuna2] = useState('');
  let [selectedValueColuna3, setSelectedValueColuna3] = useState('');

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = props.file;
    link.download = 'ficheiro-dados';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleExpandRow = (rowIndex, cellIndex) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(rowIndex)) {
      newExpandedRows.delete(rowIndex);
    } else {
      newExpandedRows.add(rowIndex);
    }
    setExpandedRows(newExpandedRows);

    const table = document.querySelector(".table-excel");
    const cell = table.rows[rowIndex].cells[cellIndex];
  
    if (newExpandedRows.has(rowIndex)) {
      cell.style.width = "300px";
    }

  };

  const truncateText = (text, rowIndex, cellIndex) => {
    let maxLength = 100;
    if (text.length > maxLength) {
        const lastSpaceIndex = text.lastIndexOf(" ", maxLength);
    
        if (lastSpaceIndex !== -1) {
          return (
            <div>
              {text.substring(0, lastSpaceIndex)}
              ...&nbsp;<Icon glyph="Unsorted" fill="#aaa" style={{ cursor: 'pointer' }} size="xlarge" className="svg-icon2"  title="Expandir célula" onClick={() => toggleExpandRow(rowIndex, cellIndex)} />
            </div>
          );
        } else {
          return (
            <div>
              {text.substring(0, maxLength)}
              ...&nbsp;<Icon glyph="Unsorted" fill="#aaa" style={{ cursor: 'pointer' }} size="xlarge" className="svg-icon2" title="Expandir célula" onClick={() => toggleExpandRow(rowIndex, cellIndex)} />
            </div>
          );
        }
      }
    return text;
  };


  useEffect(() => {
    const fetchExcelData = async () => {
      try {
        const response = await fetch(props.file);
        const arrayBuffer = await response.arrayBuffer();
        const data = new Uint8Array(arrayBuffer);

        const workbook = XLSX.read(data, { type: 'array' });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        setExcelData(jsonData);

        let i = 1;
        for (let cell in worksheet) {
            const columnIndex = cell.charCodeAt(0) - 65;
             if (worksheet[cell].v) {
                if(i===1) {
                    setNomeColuna1(worksheet[cell].v);
                    setNomeColuna1i(columnIndex);
                }
                if(i===2) {
                    setNomeColuna2(worksheet[cell].v);
                    setNomeColuna2i(columnIndex);
                }
                if(i===3) {
                    setNomeColuna3(worksheet[cell].v);
                    setNomeColuna3i(columnIndex);
                }
              i= i+1;
          }
        }
          
      } catch (error) {
        console.error('Erro ao buscar e ler o arquivo Excel:', error);
      }
    };

    fetchExcelData();
  }, [props.file]);


  useEffect(() => {
    if (excelData.length > 0) {
      const table = document.querySelector(".table-excel");
      const headerRow = table.querySelector("tr:first-child");

      // Adiciona células de entrada de texto às células do cabeçalho
      headerRow.querySelectorAll("th").forEach((th, index) => {
        const input = document.createElement("input");
        input.type = "text";
        input.placeholder = ""; // Adicione espaços para separar o ícone do texto
        input.addEventListener("input", filterTable);
      
        // Adicione uma classe ao input para estilização opcional
        //input.classList.add("search-input");
      
        // Adicione um ícone de lupa apenas na primeira célula (índice 0)
        if (index === 0) {
          input.classList.add("search-icon-input");
        }
      
        th.appendChild(input);
      });

      function filterTable() {
        const filters = headerRow.querySelectorAll("input[type='text']");
        const rows = table.querySelectorAll("tr");
      
        rows.forEach((row, rowIndex) => {
          if (rowIndex < 2) return;
          setSelectedValueColuna1('');
          setSelectedValueColuna2('');
          setSelectedValueColuna3('');
          let visible = true;
          row.querySelectorAll("td").forEach((cell, columnIndex) => {
            if (columnIndex < filters.length) {
              const filter = filters[columnIndex];
              if (filter.value.trim() !== "") {
                if (cell && cell.textContent.toLowerCase().includes(filter.value.toLowerCase())) {
                  cell.style.display = "table-cell";
                } else {
                  cell.style.display = "none";
                  visible = false;
                }
              } else {
                cell.style.display = "table-cell";
              }
            }
          });
      
          row.style.display = visible ? "table-row" : "none";
        });
      }
    
    }
  }, [excelData]);


  const getUniqueColumnValues = (columnName) => {
    const columnIndex = excelData[0].indexOf(columnName);
    const uniqueValues = new Set();
  
    for (let i = 1; i < excelData.length; i++) {
      const value = excelData[i][columnIndex];
      uniqueValues.add(value);
    }
  
    return Array.from(uniqueValues);
  };

  const handleFilterChange = (columnIndex, filterValue) => {

    let valorcoluna = [];
    valorcoluna[0] = selectedValueColuna1.toLowerCase();
    valorcoluna[1] = selectedValueColuna2.toLowerCase();
    valorcoluna[2] = selectedValueColuna3.toLowerCase();

    if (columnIndex === 0) {
        setSelectedValueColuna1(filterValue);
        valorcoluna[columnIndex] = filterValue.toLowerCase();
      } else if (columnIndex === 1) {
        setSelectedValueColuna2(filterValue);
        valorcoluna[columnIndex] = filterValue.toLowerCase();
      } else if (columnIndex === 2) {
        setSelectedValueColuna3(filterValue);
        valorcoluna[columnIndex] = filterValue.toLowerCase();
      }

    const table = document.querySelector(".table-excel");
    const rows = table.querySelectorAll("tr");
  
    rows.forEach((row, rowIndex) => {
      if (rowIndex < 2) return; // Pule a linha de cabeçalho
  
      let visible = true;
      row.querySelectorAll("td").forEach((cell, cellIndex) => {
          if (cellIndex<3) {
            if (valorcoluna[cellIndex]==='' || cell.textContent.toLowerCase() === valorcoluna[cellIndex]) {
            } else {
              visible = false;
            }
          }

      });
  
      row.style.display = visible ? "table-row" : "none";
    });
  };



  return (
    <div>
      {excelData.length > 0 ? (
        <div>
          {mostrafiltros && 
                <div className="selectors">
                <div>
                <label>Filtrar por: </label>
                <select className='filtro-seletor'
                    value={selectedValueColuna1 || ''}
                    onChange={(e) => handleFilterChange(nomeColuna1i, e.target.value)}
                >
                    <option value="">{nomeColuna1}</option>
                    {getUniqueColumnValues(nomeColuna1).map((value) => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                    ))}
                </select>

                <select className='filtro-seletor'
                    value={selectedValueColuna2 || ''}
                    onChange={(e) => handleFilterChange(nomeColuna2i, e.target.value)}
                >
                    <option value="">{nomeColuna2}</option>
                    {getUniqueColumnValues(nomeColuna2).map((value) => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                    ))}
                </select>

                <select className='filtro-seletor'
                    value={selectedValueColuna3 || ''}
                    onChange={(e) => handleFilterChange(nomeColuna3i, e.target.value)}
                >
                    <option value="">{nomeColuna3}</option>
                    {getUniqueColumnValues(nomeColuna3).map((value) => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                    ))}
                </select>
                </div>
                <div>
                <Icon glyph="Download" fill="#37474F" style={{ cursor: 'pointer' }} size="xlarge" className="svg-icon2" title="Descarregar ficheiro de dados" onClick={downloadFile} />

                </div>
                </div>
              }

<div className='table-container'>
          <table className="table-excel">
            <caption>Tabela com apresentação de dados</caption>
            <thead>
              <tr>
                {Object.keys(excelData[0]).map((columnName) => (
                  <th key={columnName}>{columnName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {excelData && excelData.length > 0 && excelData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((cell, cellIndex) => (
                    <td key={cellIndex}>
                      {expandedRows.has(rowIndex) || cell.length <= 100 ? (
                        cell.startsWith("http://") || cell.startsWith("https://") ? (
                          <a href={cell} target="_blank" rel="noopener noreferrer">
                            {cell}
                          </a>
                        ) : (
                          cell
                        )
                      ) : (
                        truncateText(cell, rowIndex, cellIndex)
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
</div>
        </div>
      ) : (
        <p>Carregando dados...</p>
      )}
    </div>
  );
}

export default ExcelTable;
