import React from "react";
import { useState, useEffect  } from 'react';
import Bdexecute from "./Bdexecute";

export default function MenuLista() {

  let [itens, setItens] = useState([]);

  useEffect(() => {
    const loadPosts = async () => {
      let results;
      let results1;
      results1 = await Bdexecute("GET", `api/struct/`);
      results = results1.filter(item => item.principal === "1")[0];
      setItens(results);
    }
  
    loadPosts();
  }, []);

  return (
    <React.Fragment>

      {itens.submenu && itens.submenu.sort((a, b) => a.ordem - b.ordem).map((item1) => ( item1.oculto !== true && 
          <li className="texto-rodape"><a key={item1._id} href={`/pagina/${itens._id}/${item1._id}`}>{item1.menu}</a></li>
        ))}

    </React.Fragment>
  );
}