import React, {useState } from "react";

export default function PostPublicacoes(props) {


  const [hover, setHover] = useState(false);
  const optionsdate = { year: 'numeric' };
  
  return (
<React.Fragment>
<div className="grelha-container">
        {props.artigos.map((post) => (
          <span
            key={post._id}
            className="grelha-itemp"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className="grelha-item-conteudo">
              <a href={post.pdf} alt={post.title} target="_blank" rel="noopener noreferrer">
              <div>
              <a className="grelha-item-titulo" alt={post.title} href={`/art/${props.id}/${props.id2}/${props.id3}/${post._id}`} >{post.title}</a>
            </div>
              <div
                className={`grelha-item-imagem ${hover ? "hover" : ""}`}
                style={{ backgroundImage: `url('${post.imagem ? post.imagem.replace(/ /g, "%20") : ""}')` }}
              ></div>
              </a>
            </div>
            <div className="grelha-item-links">
              {post.pdf && 
              <a href={post.pdf} target="_blank" alt={`Descarregar PDF sobre ${post.title}`} rel="noopener noreferrer"><img src="/pdf-icon.png" style={{width: '50px', margin: '4px', }} alt={`Descarregar PDF sobre ${post.title}`} title="Descarregar PDF" /></a>
              }
              {post.xls && 
              <a href={post.xls} target="_blank" alt={`Descarregar excel sobre ${post.title}`} rel="noopener noreferrer"><img src="/xls-icon.png" style={{width: '50px', margin: '4px', }} alt={`Descarregar excel sobre ${post.title}`} title="Descarregar XLS" /></a>
              }
              {post.ods && 
              <a href={post.ods} target="_blank" alt={`Descarregar ods sobre ${post.title}`} rel="noopener noreferrer"><img src="/ods-icon.png" style={{width: '50px', margin: '4px', }} alt={`Descarregar ods sobre ${post.title}`} title="Descarregar ODS" /></a>
              }
              </div>

              <p className="grelha-item-data">Ano da edição: {(new Date(post.datapublicacao)).toLocaleDateString('pt-PT', optionsdate)}</p>
          </span>
        ))}
        
  </div>
</React.Fragment>

  );
}
