import React, {useState, useEffect } from "react";
import Bdexecute from "../components/Bdexecute";
import { useParams } from "react-router-dom";
import PostAgendaVer from "../components/PostAgendaVer";
import moment from 'moment';
import { css } from "@leafygreen-ui/emotion";
import { Calendar, Col, Radio, Row, Badge } from 'antd';
import ptPT from 'antd/lib/calendar/locale/pt_PT';
import { Link } from 'react-router-dom';
import { Select, Option } from "@leafygreen-ui/select";

const classcalendar = css`
  padding: 10px;
`

export default function App() {
  let params = useParams();
  let [artigosAgenda, setartigosAgenda] = useState();
  let [postId, setPostId] = useState(params.id);
  let isFirstActiveItem = true;
  const currentDate = moment();
  let [filtro, setFiltro] = useState();
  let [menu, setMenu] = useState();
  let options = [];
  const atualizarCalendario = false;

    useEffect(() => {
      const loadPosts = async () => {
        let resultsa = await Bdexecute("GET", `api/agenda`)
        setartigosAgenda(resultsa);
      }
    
      loadPosts();
    }, []);

    
    artigosAgenda && artigosAgenda.map((secundario, index) => {
      const startDate = moment(secundario.startDate);
      const isActive = startDate.isSameOrAfter(currentDate, 'day');
    
      if (isFirstActiveItem && isActive) {
        if (!postId) { // Verifica se o postId ainda não foi definido
          setPostId(secundario._id); // Atualiza o ID do post ativo
        }
        isFirstActiveItem = false;
      }
      return isFirstActiveItem;
    });


    // Função para atualizar o componente com base no ID
    const handlePostUpdate = (id) => {
      setPostId(id);
    }

    const getListData = (value) => {
      const datacell = [value.year(), value.month()+1, value.date()].join("-");
      const dateString = moment(datacell).format('YYYY-MM-DD');
      const filteredEvents = artigosAgenda.filter((event) => {
        const eventStartDate = moment(event.startDate, 'YYYY-MM-DD');
        const eventEndDate = moment(event.endDate, 'YYYY-MM-DD');
        return eventStartDate.isSameOrBefore(dateString, 'day') && eventEndDate.isSameOrAfter(dateString, 'day');
      });

      return filteredEvents
      .filter((event) => {
        if (event && typeof event === 'object' && event.assunto) {
          if (filtro && filtro.trim() !== "") {
            return event.assunto.includes(filtro);
          }
          return true;
        }
        return false;
      })
      .map((event) => {

        let type = '';
  
        switch (event.tipoevento) {
          case 'Forum Estatístico':
            type = 'processing';
            break;
          case 'Inquérito':
            type = 'error';
            break;
          case 'Publicação':
            type = 'success';
            break;
          default:
            type = 'warning';
            break;
        }
  
        return {
          type,
          content: `${event.evento}`,
          id: `${event._id}`,
          tipo: `${event.tipoevento}`,
        };
      });
    };

    const getListDataMes = (value) => {
      const datacell = [value.year(), value.month()+1, value.date()].join("-");
      const dateString = moment(datacell).format('YYYY-MM-DD');
      const filteredEvents = artigosAgenda.filter((event) => {
        const eventStartDate = moment(event.startDate, 'YYYY-MM-DD');
        const eventEndDate = moment(event.endDate, 'YYYY-MM-DD');
        return eventStartDate.isSameOrBefore(dateString, 'month') && eventEndDate.isSameOrAfter(dateString, 'month');
      });

      return filteredEvents
      .filter((event) => {
        if (event && typeof event === 'object' && event.assunto) {
          if (filtro && filtro.trim() !== "") {
            return event.assunto.includes(filtro);
          }
          return true;
        }
        return false;
      })
      .map((event) => {
        let type = '';
  
        switch (event.tipoevento) {
          case 'Forum Estatístico':
            type = 'processing';
            break;
          case 'Inquérito':
            type = 'error';
            break;
          case 'Publicação':
            type = 'success';
            break;
          default:
            type = 'warning';
            break;
        }
  
        return {
          type,
          content: `${event.evento}`,
          id: `${event._id}`,
          tipo: `${event.tipoevento}`,
        };
      });
    };



    const monthCellRender = (value) => {
      const listData = artigosAgenda ? getListDataMes(value) : [];
      return (
        <ul className="events">
          {listData.map((item) => ( 
            <li key={item.id} onClick={() => handlePostUpdate(item.id)} >
              <Badge status={item.type} text={item.content} />
            </li>
          ))}
        </ul>
      );
    };

    const dateCellRender = (value) => {
      const listData = artigosAgenda ? getListData(value) : [];
      return (
        <ul className="events">
          {listData.map((item) => ( 
            <li key={item.id} onClick={() => handlePostUpdate(item.id)} >
              <Badge status={item.type} text={item.content} />
            </li>
          ))}
        </ul>
      );
    };

    const cellRender = (current, info) => {
      if (info.type === 'date') return dateCellRender(current);
      if (info.type === 'month') return monthCellRender(current);
      return info.originNode;
    };

    useEffect(() => {
      const fetchData = async () => {
        const menuCompleto = await Bdexecute("GET", "api/struct");
        setMenu(menuCompleto);
      };
    
      fetchData();
    }, []);


    if (menu) {
      options = menu.map(item => ({
        value: item._id,
        label: item.menu,
      }));
    }


  return (
    <React.Fragment>

      <div className="container-cinza">
      <div className="container">

      <div className="class-page-agenda">

      <div id="seccaoprincipal">

      <h1 className="h1style">Calendário de atividades e eventos</h1>
      
      <Select
              align="top"
              placeholder="Selecione"
              darkMode={false}
              data-test="hello"
              description=""
              justify="start"
              label="Filtre por área"
              readOnly={true}
              usePortal={true}
              onChange={setFiltro}
              value={filtro}
              options={options}
              style={{
                width: 300,
              }}
              >
                {options.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>

      <Calendar 
      locale={ptPT} 
      className={classcalendar} 
      cellRender={cellRender} 
      key={atualizarCalendario}
      colorSplit='#1677ff'
      fullscreen={false}
      headerRender={({ value, type, onChange, onTypeChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];
        const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        for (let i = start; i < end; i++) {
          monthOptions.push(
          <Option key={i} value={i} className="month-item">
          {months[i]}
          </Option>,
          );
        }
        const year = value.year();
        const month = value.month();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
          options.push(
          <Option key={i} value={i} className="year-item">
          {i}
          </Option>,
          );
        }
        return (
          <div
            style={{
              padding: 8,
            }}
          >
            <Row gutter={8}>
              <Col style={{ width: '25%',}}>
              <Select
              align="top"
              placeholder="Selecione"
              darkMode={false}
              data-test="hello"
              description=""
              justify="start"
              label="Ano"
              className="my-year-select"
              readOnly={true}
              usePortal={true}
              onChange={(newYear) => {
                const now = value.clone().year(newYear);
                onChange(now);
              }}
              value={year}
              style={{
                width: 50,
              }}
              >
                 {options}
                </Select>
              </Col>
              <Col style={{ width: '25%'}}>
              <Select
              align="top"
              placeholder="Selecione"
              darkMode={false}
              data-test="hello"
              description=""
              justify="start"
              label="Mês"
              readOnly={true}
              usePortal={true}
              onChange={(newMonth) => {
                const now = value.clone().month(newMonth);
                onChange(now);
              }}
              value={month}
              >
                  {monthOptions}
                </Select>
              </Col>
              <Col style={{ textAlign: 'right', width: '50%' }}>
                <Radio.Group
                  onChange={(e) => onTypeChange(e.target.value)}
                  value={type}
                >
                  <Radio.Button value="month">Ver por Mês</Radio.Button>
                  <Radio.Button value="year">Ver por Ano</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </div>
        );
      }}
      
      />
      <span className="bolinha-azul"></span> Forum Estatístico
      <span className="bolinha-vermelha"></span> Inquérito
      <span className="bolinha-verde"></span> Publicação
      <span className="bolinha-laranja"></span> Evento
      </div>

      <div>
        <p>&nbsp;</p>
      {postId && <PostAgendaVer key={postId} id={postId} />}
      </div>

      </div>
      </div>
      <p>&nbsp;</p>
    <div className="center">
    <Link className='buttonSecundario' to="/">VOLTAR À PÁGINA PRINCIPAL</Link>
    </div>
    </div>
    </React.Fragment>
  )
}
