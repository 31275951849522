import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TextArea from "@leafygreen-ui/text-area";
import Checkbox from "@leafygreen-ui/checkbox";
import Bdexecute from "../components/Bdexecute";

function MeuFormulario() {
  let params = useParams();
  let [pedidodados, setPedidoDados] = useState([]);

  const [dadosFormulario, setDadosFormulario] = useState({
    nome: '',
    email: '',
    telefone: '',
    descricao: '',
    motivo: '',
  });

  const [basicosecundario, setBasicoSecundario] = useState(false);
  const [ensinosuperior, setEnsinosuperior] = useState(false);
  const [cienciatecnologia, setCienciatecnologia] = useState(false);
  const [inovacao, setInovacao] = useState(false);
  const [sociedadeinformacao, setSociedadeinformacao] = useState(false);
  const [producaocientifica, setProducaocientifica] = useState(false);
  const [outros, setOutros] = useState(false);
  const [numero, setNumero] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const loadPosts = async () => {
      try {
        let results = await Bdexecute("GET", `api/pedidodados/${params.id}`);
        if (isMounted) {
          setPedidoDados(results);
        }
      } catch (error) {
        console.error(error);
      }
    }
    loadPosts();
    return () => {
      isMounted = false;
    };
  });


  useEffect(() => {
    if (!pedidodados._id) { return }
    setDadosFormulario(pedidodados.dadosFormulario);
    setBasicoSecundario(pedidodados.basicosecundario);
    setEnsinosuperior(pedidodados.ensinosuperior);
    setCienciatecnologia(pedidodados.cienciatecnologia);
    setInovacao(pedidodados.inovacao);
    setSociedadeinformacao(pedidodados.sociedadeinformacao);
    setProducaocientifica(pedidodados.producaocientifica);
    setOutros(pedidodados.outros);
    setNumero(pedidodados.numero);
  }, [pedidodados]);

  return (
    <div className="container-cinza">
      <div className='container'>
        <h1 className='h1style'>Pedido personalizado de dados/informação</h1>
        <div className='container'>
        <p><strong>Número {numero}</strong></p>
            <p>Dados a que se respeita</p>
            <Checkbox
              description=""
              label="Ensino básico e secundário"
              checked={basicosecundario}
            />
            <Checkbox
              description=""
              label="Ensino Superior"
              checked={ensinosuperior}
            />
            <Checkbox
              description=""
              label="Ciência e Tecnologia"
              checked={cienciatecnologia}
            />
            <Checkbox
              description=""
              label="Inovação"
              checked={inovacao}
            />
            <Checkbox
              description=""
              label="Sociedade de Informação"
              checked={sociedadeinformacao}
            />
            <Checkbox
              description=""
              label="Produção Científica"
              checked={producaocientifica}
            />
            <Checkbox
              description=""
              label="Outros"
              checked={outros}
            />

            <br />

            <TextArea
              label="Descrição pormenorizada do pedido"
              name="descricao"
              description=""
              rows="6"
              value={dadosFormulario.descricao}
            />

            <TextArea
              label="Motivo/Objetivos"
              name="motivo"
              description=""
              rows="6"
              value={dadosFormulario.motivo}
            />

        </div>
      </div>
    </div>
  );
}

export default MeuFormulario;

