import React, {useState, useEffect} from "react";
import { H2 } from "@leafygreen-ui/typography";
import Icon from "@leafygreen-ui/icon";
import { css } from "@leafygreen-ui/emotion";
import Card from "@leafygreen-ui/card";
import Bdexecute from "../components/Bdexecute";
import { Popover, Steps } from 'antd';


const iconStylesmallonline = css`
color: #90CAF9;
vertical-align: -0.3em;
margin-left: 12px;
margin-right: 8px;
`;

const iconStylesmalllocal = css`
color: #B0BEC5;
vertical-align: -0.125em;
margin-left: 12px;
`;

const iconStylesmallinscricao = css`
color: #82C685;
vertical-align: -0.3em;
margin-left: 12px;
margin-right: 8px;
`;

const iconStylesmalldata = css`
color: #C5E1A5;
vertical-align: -0.3em;
margin-left: 12px;
margin-right: 8px;
`;


const customDot = (dot, { sit, index }) => (
    <Popover
    >
      {dot}
    </Popover>
  );

const cardStyle = css`
  border-radius: 0px !important;
  border: 0px !important;
  font-size: 1.1em !important;
  line-height: 1.3 !important;
  text-align: justify;
  text-justify: inter-word;
  font-family: "Lato", Arial, sans-serif;
  box-shadow: none !important;
  background-color: transparent !important;
`


const imgStyle = css`
max-width: 100%;
max-height: 400px;
display: block;
margin-left: auto;
margin-right: auto;
cursor: pointer;
`

export default function PostAgendaVer(props) {
  let [post, setPost] = useState({});
  const optionsdate = { day: 'numeric',  month: 'long', year: 'numeric' };
  const optionshour = {hour: '2-digit', minute:'2-digit'};
  let [menu, setMenu] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderTextWithLineBreaks = (text) => {
    const lines = text.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };


  useEffect(() => {
    const loadPost = async () => {
      let results = await Bdexecute("GET", `api/evento/${props.id}`);
      setPost(results);
    }

    loadPost();
  },[props.id]);

  useEffect(() => {
    const fetchData = async () => {
      const menuCompleto = await Bdexecute("GET", "api/struct");
      setMenu(menuCompleto);
    };
  
    fetchData();
  }, []);

  const classTipoEventoCor = (value) => {
    switch (value) {
      case 'Forum Estatístico':
        return 'texto-azul';
      case 'Inquérito':
        return 'texto-vermelho';
        case 'Publicação':
            return 'texto-verde';
      default:
        return 'texto-laranja';
    }
  };

  const className = classTipoEventoCor(post.tipoevento);

  const classDataEvento = () => {
  let stepsItems;
  if (post.startDate !== post.endDate) {
    stepsItems = [
      {
        title: `${new Date(post.startDate).toLocaleDateString('pt-PT', optionsdate)}`,
        description: `${new Date(post.startDate).toLocaleTimeString('pt-PT', optionshour)}`,
        status: 'wait',
      },
      {
        title: `${new Date(post.endDate).toLocaleDateString('pt-PT', optionsdate)}`,
        description: `${new Date(post.endDate).toLocaleTimeString('pt-PT', optionshour)}`,
        status: 'wait',
      },
    ];

    return (
    <Steps
    current={1}
    progressDot={customDot}
    items={stepsItems}
    />
    );

  } else {
    return (
        <p><Icon glyph="Calendar" size="large" className={iconStylesmalldata} />{new Date(post.startDate).toLocaleDateString('pt-PT', optionsdate)}</p>
    );
  }
}

let assuntosString = '';
if (post.assunto && menu) {
  const assuntoDescricao = post.assunto.map((id) => {
    const item = menu.find((item) => item._id === id);
    return item ? item.menu : "";
  });
  assuntosString = assuntoDescricao.join(", ");
}


let [artigosposts, setArtigosposts] = useState([]);

useEffect(() => {
  const loadArtigosPosts = async () => {
    try {
      let results = await Bdexecute("GET", `api/agendaartigo/${props.id}`);
      setArtigosposts(results);
    } catch (error) {
      console.error(error);
    }
  };

  loadArtigosPosts();
}, [props.id]);

let [artigospostsano, setArtigospostsano] = useState([]);

useEffect(() => {
  const loadArtigosPostsano = async () => {
    try {
      let results = await Bdexecute("GET", `api/agendaartigoano/${props.id}`);
      setArtigospostsano(results);
    } catch (error) {
      console.error(error);
    }
  };

  loadArtigosPostsano();
}, [props.id]);

  return (
    <React.Fragment>
      <Card className={cardStyle}>
      <span className="texto-cinza">{assuntosString}</span>
      <H2 className="esquerda">{post.evento}</H2>
      <hr className="linha" />
      <p className={className}>{post.tipoevento}</p>
        {post.cartaz && (
              <div>
              <img
                src={post.cartaz}
                alt={post.evento}
                className={imgStyle}
                onClick={openModal}
              />
              {isModalOpen && (
                <div className="modal open"> {/* Adicione a classe "open" quando o modal estiver aberto */}
                  <div className="modal-content">
                    <span className="close" onClick={closeModal}>
                    <Icon glyph="XWithCircle" size="large" />
                    </span>
                    <img src={post.cartaz} alt={post.evento} className="modal-image" />
                  </div>
                </div>
              )}
            </div>
        )}

        
        <div className="calendario-contactos">
        {classDataEvento()}

        { post.local && 
        <p>
            <div className="local-icon-text-container">
            <div className="local-icon-container">
                <svg className={iconStylesmalllocal} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                </svg>
            </div>
            <div className="local-text-container">
                <p className="calendar-link">{renderTextWithLineBreaks(post.local)}</p>
            </div>
            </div>
        </p>
        }

        { post.inscricao &&
        <p><Icon glyph="InviteUser" size="large" className={iconStylesmallinscricao} />
        <a className="calendar-link" target="_blank" rel="noopener noreferrer" href={post.inscricao}>Inscrição</a></p>
        }


        { post.sessaoonline && 
        <p><Icon glyph="GlobeAmericas" size="large" className={iconStylesmallonline} />
        <a className="calendar-link" target="_blank" rel="noopener noreferrer" href={post.sessaoonline}>Sessão online</a></p>
        }

        { post.sabermais && 
        <a className="buttonSecundario" target="_blank" rel="noopener noreferrer" href={post.sabermais}>SABER MAIS</a>
        }

          {artigosposts && artigosposts.length > 0 && artigosposts.map((item) => ( 
            <a className="buttonSecundario" href={`/artpub/${item._id}`} key={item._id}>
              MAIS INFORMAÇÃO
            </a>
          ))}

        {artigospostsano && artigospostsano.length > 0 && artigospostsano.map((item) => ( 
            <a className="buttonSecundario" href={`/artpub/${item.link}`} key={item._id}>
              MAIS INFORMAÇÃO
            </a>
          ))}


        </div>

        { post.descricao && 
        <p><p dangerouslySetInnerHTML={{ __html: post.descricao }} /></p>
        }

      </Card>
    </React.Fragment>
  )
}