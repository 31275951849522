import React from 'react';
import ReactDOM from 'react-dom';
import { css } from '@leafygreen-ui/emotion';
import dotenv from 'dotenv';
import App from './App';
import reportWebVitals from './reportWebVitals';

dotenv.config();

export const globalStyles = css`
  html {
    font-family: "Lato", Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
  }

  body {
    margin: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <div className={globalStyles}>
      <App />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

document.documentElement.lang = 'pt';

reportWebVitals();
