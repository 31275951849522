import React, { useState, useEffect } from "react";
import Bdexecute from "./Bdexecute";

export default function PostGrelha(props) {
  const [images, setImages] = useState({});

    useEffect(() => {
      const loadImages = async () => {
          const newImages = {};
          for (const artigo of props.artigos) {
              if (!artigo.imagem && artigo._id) {
                  const result = await Bdexecute("GET", `api/postsano/${artigo._id}`);
                  newImages[artigo._id] = result[0]?.imagem;
                  //console.log(artigo._id);
                  //console.log(newImages[artigo._id])
                  //console.log(result[0]?.imagem)
              } else {
                  newImages[artigo._id] = artigo.imagem;
              }
          }
          setImages(newImages);
      }; 

      loadImages();
  }, [props.artigos]);

  console.log(images)

  return (
    <React.Fragment>
      <div className="grelha-container">
        {props.artigos.map((secundario) => {
          const image = images[secundario._id];

          console.log(image)

          const linkurl = secundario.link ? secundario.link : `/art/${props.id}/${props.id2}/${props.id3}/${secundario._id}`;
          return (
            <a
              href={linkurl}
              className="grelha-item-recentes"
              aria-label={`Ir para página intitulada de ${secundario.title}`}
              key={secundario._id}
            >

              <div className="grelha-item-conteudo-recentes">
                  <span className="grelha-item-titulo-recentes">{secundario.title}</span>
                </div>
                { image ? (
                <div
                  className={`grelha-item-imagem`}
                  style={{ backgroundImage: `url('${image ? image.replace(/ /g, "%20") : ""}')` }}
                ></div>
                ) : ( 
                  <>
                  <br />
                  <span className="grelha-item-link-recentes">SABER MAIS</span>
                  </>
                )}

            </a>
          );
        })}
      </div>

    </React.Fragment>
  );
}
