import React, {useState, useEffect} from "react";
import Bdexecute from "../components/Bdexecute";
import { useParams } from "react-router-dom";

export default function App() {
    let params = useParams();
    let [posts, setPosts] = useState([]);

  useEffect(() => {
    const loadPosts = async () => {
      let results = await Bdexecute("GET", `api/shortlink/${params.id}`);
      setPosts(results);

      if (results.originalLink) {
        window.location.href = results.originalLink;
      }
    }

    loadPosts();
  }, [params.id]);


  return (
    <React.Fragment>
        <div className="container-cinza">
        <div className="container">
        <p>A reencaminhar para <a href={posts.originalLink}>{posts.originalLink}</a></p>
        </div>
        </div>
    </React.Fragment>
  )
}