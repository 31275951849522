import React from "react";
import Card from "@leafygreen-ui/card";
import { css } from "@leafygreen-ui/emotion";
import Icon from "@leafygreen-ui/icon";

const cardStyle = css`
  margin-top: 6px;
  border-radius: 0px !important;
  font-size: 1em !important;
  line-height: 1.3 !important;
  display: flex; /* Usando flexbox para o layout */
  justify-content: space-between; /* Alinha os elementos horizontalmente */
  align-items: center; /* Alinha os elementos verticalmente */
  box-shadow: none !important;
  color: #001E2B !important;
  padding: 0px 24px 0px 24px !important;
  transition: transform 0.2s ease;
  text-decoration: none;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 10px -4px rgba(0,30,43,0.3) !important;
  }
`;

const iconStyle = css`
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.5);
  }
`;


export default function PostLista(props) {

  const linkurl = props.link ? props.link : `/art/${props.id}/${props.id2}/${props.id3}/${props._id}`;

  return (
    <React.Fragment>   
      <Card className={cardStyle} href={linkurl}>
      <h2 className="titulo-lista">{props.title}</h2>
        <div>
        {<Icon glyph="ChevronRight" size="large" className={iconStyle} />}
        </div>
      </Card>
    </React.Fragment>
  );
}