import React, { useRef, useEffect } from "react";
import { css } from "@leafygreen-ui/emotion";
import Icon from "@leafygreen-ui/icon";
import moment from "moment";

const iconStylesmall = css`
color: #C5E1A5;
vertical-align: -0.125em;
margin-right: 2px;
`;

const iconStylesmallonline = css`
color: #90CAF9;
vertical-align: -0.125em;
margin-right: 2px;
margin-left: 10px;
`;

const diviconStyle = css`
text-align: right;
margin-right: 20px;
margin-left: auto;
margin-top: 30px; 
`;

const iconStyle = css`
  transition: transform 0.3s ease;
  color:#37474F;

  &:hover {
    color: #d41a28;
    transform: scale(1.5);
  }
`;

export default function PostAgenda(props) {
  const optionsdatedia = { day: 'numeric' };
  const optionsdatemes = { month: 'short' };
  const optionsdateano= { year: 'numeric' };
  const currentDate = moment();
  let isFirstActiveItem = true;
  const activeItemRef = useRef(null);

  useEffect(() => {
    const container = document.querySelector(".agenda-container");
    const activeItem = document.querySelector(".agenda-active-item");
  
    if (container && activeItem) {
      const containerTop = container.offsetTop;
      const activeItemTop = activeItem.offsetTop;
  
      const scrollTop = activeItemTop - containerTop - (container.clientHeight / 2) + (activeItem.clientHeight / 2);
  
      container.scrollTo({
        top: scrollTop,
        behavior: 'smooth'
      });
    }
  }, []);


  return (
    <React.Fragment>
      <h2 className='h1style texto-cinza'>Agenda</h2>
      <div className="agenda-container">
      {props.artigos.map((secundario, index) => {
          const startDate = moment(secundario.startDate);
          const isActive = startDate.isSameOrAfter(currentDate, 'day');
          const itemClasses = isFirstActiveItem && isActive ? 'agenda-active-item' : '';

          const datames = (new Date(secundario.startDate)).toLocaleDateString('pt-PT', optionsdatemes);
          const dataSemUltimoCaracter = datames.substring(0, datames.length - 1);
          

          if (isActive) {
            isFirstActiveItem = false;
          }

          return (
            <a
              href={`/agenda/${secundario._id}`}
              className={`agenda-item ${itemClasses}`}
              ref={isActive ? activeItemRef : null}
              key={secundario._id}
            >
              <div className="agenda-item-conteudo">
                  <div className="agenda-item-data">
                    {(new Date(secundario.startDate)).toLocaleDateString('pt-PT', optionsdatedia)} <br />
                    {dataSemUltimoCaracter} <br />
                    <div className="agenda-item-data-ano">{(new Date(secundario.startDate)).toLocaleDateString('pt-PT', optionsdateano)}</div>
                    </div>
                  <div>
                  <p className="agenda-item-titulo">{secundario.evento}</p>
                  <p className="agenda-tipo-evento">
                    {<Icon glyph="Calendar" size="small" className={iconStylesmall} />} {secundario.tipoevento}

                    {secundario.sessaoonline && (
                      <>
                          <Icon glyph="GlobeAmericas" size="small" className={iconStylesmallonline} />Online
                          </>
                      )}
                    </p>
                  </div>
                  <div className={diviconStyle}>
                  {<Icon glyph="ChevronRight" size="large" className={iconStyle} />}
                  </div>
                </div>
            </a>
          );
        })}
      </div>
      <div className="agenda-mais">
      <a className='buttonPrincipal' style={{ width: '100%', margin: '0px', borderColor: '#E0E0E0' }} href='/agenda'>CONSULTAR AGENDA</a>
      </div>
    </React.Fragment>
  );
}
