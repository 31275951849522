import React, {useState, useEffect} from "react";
import StructSummary from "../components/StructSummary";
import Bdexecute from "../components/Bdexecute";

export default function App() {

    let [posts, setPosts] = useState([]);



  useEffect(() => {
    const loadPosts = async () => {
      let results = await Bdexecute("GET", "api/struct");
      setPosts(results);
    }

    loadPosts();
  }, []);

  return (
    <React.Fragment>
      
      <div className="container-cinza">
      <div className="container">
          <h1 className="h1style">Mapa do site</h1>
          <div className="grelha-container" id="seccaoprincipal">
        {posts.map(post => {
          return(
            <StructSummary {...post} key={post._id} />
          )
        })}
        </div>
      </div>
      </div>
    </React.Fragment>
  )
}