import CryptoJS from "crypto-js";

const baseUrl = process.env.REACT_APP_BASE_URL;
const secretKey = process.env.REACT_APP_SECRET_KEY;

const Bdexecute = async (method, url, data) => {

    if (method === "DELETE") {
            const response = await fetch(`${baseUrl}/${url}`, {
                method: "DELETE"
            });
            return response;
    
    } else if (method === "GET") {

        const response = await fetch(`${baseUrl}/${url}`);
        const results = await response.text();
        const responsedec = JSON.parse(CryptoJS.AES.decrypt(results, secretKey).toString(CryptoJS.enc.Utf8));
        return responsedec;

    } else {

        let crypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        let requestData = { body: crypted };
        let jsonString = JSON.stringify(requestData);

        try {
            const response = await fetch(`${baseUrl}/${url}`, {
                method: method,
                headers: {
                "content-type": "application/json"
                },
                body: jsonString
            });
            const result = await response.json();
            return result;

        } catch (error) {
            console.error(error);
        }
    };
};

export default Bdexecute;