import React from "react";

export default function PostGrelha(props) {

  const optionsdate = { year: 'numeric' };

  return (
    <React.Fragment>
      {/* props.tit === 'P' && (
      <h2>Projetos</h2>
      )*/}
      
      <div className="grelha-container">
        {props.artigos.map((secundario) => {
          const linkurl = secundario.link ? secundario.link : `/art/${props.id}/${props.id2}/${props.id3}/${secundario._id}`;
          return (
            <span
              className="grelha-item"
            >
              <a href={linkurl} className="grelha-item-titulo" aria-label={`Ir para página intitulada de ${secundario.title}`}>
              <div className="grelha-item-conteudo">
                <div
                  className={`grelha-item-imagem`}
                  style={{ backgroundImage: `url('${secundario.imagem ? secundario.imagem.replace(/ /g, "%20") : ""}')` }}
                ></div>
                
                </div>
                {secundario.title &&
                  secundario.title
                }
                </a>

              <div className="grelha-item-links">
              {secundario.pdf && 
              <a href={secundario.pdf} alt={`Descarregar PDF sobre ${secundario.title}`} target="_blank" rel="noopener noreferrer"><img src="/pdf-icon.png" style={{width: '50px', margin: '4px', }} alt={`Descarregar PDF sobre ${secundario.title}`}  title="Descarregar PDF" /></a>
              }
              {secundario.xls && 
              <a href={secundario.xls} alt={`Descarregar excel sobre ${secundario.title}`}  target="_blank" rel="noopener noreferrer"><img src="/xls-icon.png" style={{width: '50px', margin: '4px', }} alt={`Descarregar excel sobre ${secundario.title}`}  title="Descarregar XLS" /></a>
              }
              {secundario.ods && 
              <a href={secundario.ods} alt={`Descarregar ods sobre ${secundario.title}`}  target="_blank" rel="noopener noreferrer"><img src="/ods-icon.png" style={{width: '50px', margin: '4px', }} alt={`Descarregar ods sobre ${secundario.title}`}  title="Descarregar ODS" /></a>
              }
              </div>

                {props.tit !== 'P' &&
                <p className="grelha-item-data">Ano da edição: {(new Date(secundario.datapublicacao)).toLocaleDateString('pt-PT', optionsdate)}</p>
                }
            </span>
          );
        })}
      </div>
    </React.Fragment>
  );
}
