import React, {useState, useEffect } from "react";
import PostComplete from "../components/PostComplete";
import PostExpansor from "../components/PostExpansor";
import PostLista from "../components/PostLista";
import PostGrelha from "../components/PostGrelha";
import PostPublicacoes from "../components/PostPublicacoes";
import Bdexecute from "../components/Bdexecute";
import Menu from "../components/Menu";
import { useParams } from "react-router-dom";
import { Breadcrumb } from 'antd';

export default function App() {
  let params = useParams();
  let [posts, setPosts] = useState([]);

  useEffect(() => {
    const loadPosts = async () => {
      let results;
      if (params.id3) {
        results = await Bdexecute("GET", `api/posts/menu/${params.id3}`);
      } else if (params.id2) {
        results = await Bdexecute("GET", `api/posts/menu/${params.id2}`);
      } else if (params.id) {
        results = await Bdexecute("GET", `api/posts/menu/${params.id}`);
      }
      setPosts(results);
    }
  
    loadPosts();
  }, [params.id2, params.id3, params.id]);
  
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

    const postsatuais = posts.filter(post => post.datapublicacao < formattedDate);

    const artigosCompletos = postsatuais.filter(post => post.apresentacao === '2');
    const artigosGrelha = postsatuais.filter(post => post.apresentacao === '3');
    const artigosLista = postsatuais.filter(post => post.apresentacao === '4');
    const artigosExpansor = postsatuais.filter(post => post.apresentacao === '5');
    const artigosPublicacoes = postsatuais.filter(post => post.apresentacao === '6');


//breadcumb
let [itens, setItens] = useState([]);
useEffect(() => {
  const loadPostsMenu = async () => {
    let resultsmenu;
    resultsmenu = await Bdexecute("GET", `api/struct/`);
    setItens(resultsmenu);
  }

  loadPostsMenu();
}, []);


let [seccao, setSeccao] = useState();
let [menutop, setMenutop] = useState();
let [menupag, setMenupag] = useState();
useEffect(() => {
  if (!itens) { return }

  const findMenuByIdRecursive = (arr, targetId) => {
    for (const item of arr) {
      if (item._id === targetId) {
        return item.menu;
      }
      if (item.submenu && Array.isArray(item.submenu)) {
        const menu = findMenuByIdRecursive(item.submenu, targetId);
        if (menu) {
          return menu;
        }
      }
    }
    return null;
  };

  setSeccao(findMenuByIdRecursive(itens, params.id));
  setMenutop(findMenuByIdRecursive(itens, params.id2));
  setMenupag(findMenuByIdRecursive(itens, params.id3));
}, [itens, params]);

let titulopagina = menupag ? menupag : menutop;

  return (
    <React.Fragment>

      <Menu id={params.id} id2={params.id2} id3={params.id3} />
      <div className="container-cinza">
      <div className="container">

      <Breadcrumb
      separator=">"
        items={[
          {
            title: <a href="/" alt={`Ligação para a página principal`}>Início</a>,
          },
          params.id && params.id.length > 10 ? {
            title: <a href={`/pagina/${params.id}`} alt={`Ligação para ${seccao}`}>{seccao}</a>,
          } : '',
          params.id2 && params.id2.length > 10 ? {
            title:  <a href={`/pagina/${params.id}/${params.id2}`} alt={`Ligação para ${menutop}`}>{menutop}</a>,
          } : '',
          params.id3 && params.id3.length > 10 ? {
            title: <a href={`/pagina/${params.id}/${params.id2}/${params.id3}`} alt={`Ligação para ${menupag}`}>{menupag}</a>,
          } : '',
        ]}
      />

      </div>
      </div>
      <div className="container">
      {titulopagina && 
      <div class="areaboxtitulo esquerda" id="seccaoprincipal"><h1 className="h1style">{titulopagina}</h1></div>
      }
      <div>
      {artigosCompletos.map((artigo) => (
          <PostComplete {...artigo} key={artigo._id} id={params.id} id2={params.id2} id3={params.id3}  />
        ))}
      </div>

      <div>
          {artigosGrelha && artigosGrelha.length > 0 && (
          <PostGrelha artigos={artigosGrelha} key='yw73wlisjdf8373' id={params.id} id2={params.id2} id3={params.id3} />
          )}
      </div>

      <div>
          {artigosPublicacoes && artigosPublicacoes.length > 0 && (
          <PostPublicacoes artigos={artigosPublicacoes} key='3ru28urijequ823jrij' id={params.id} id2={params.id2} id3={params.id3} />
          )}
      </div>

      <div>
      {artigosExpansor.map((artigo) => (
          <PostExpansor {...artigo} key={artigo._id} id={params.id} id2={params.id2} id3={params.id3} id4={artigo._id} />
        ))}
      </div>
      
      <div>
      {artigosLista.map((artigo) => (
          <PostLista {...artigo} key={artigo._id} id={params.id} id2={params.id2} id3={params.id3} id4={artigo._id} />
        ))}
      </div>
      </div>
    </React.Fragment>
  )
}