import React, {useState, useEffect } from "react";
import PostGrelha from "../components/PostGrelha";
import Bdexecute from "../components/Bdexecute";
import { Breadcrumb } from 'antd';


export default function App() {
  let [posts, setPosts] = useState([]);

  useEffect(() => {
    const loadPosts = async () => {
      let results = await Bdexecute("GET", "api/destaques");
      setPosts(results);
    }
  
    loadPosts();
  }, []);
  
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

    const postsatuais = posts.filter(post => post.datapublicacao < formattedDate);

    const sortedPosts = postsatuais.sort((a, b) => new Date(b.dataartigo) - new Date(a.dataartigo));

  return (
    <React.Fragment>

      <div className="container-cinza">
      <div className="container">

      <Breadcrumb
      separator=">"
        items={[
          {
            title: <a href="/" alt={`Ligação para a página principal`}>Início</a>,
            path: '/',
          },
          {
            title: 'Destaques',
          },
        ]}
      />

      </div>
      </div>
      <div className="container">

<div className="areaboxtitulop esquerda" id="seccaoprincipal">
<h1 className="h1style">Destaques</h1>
</div>
<br />

        {sortedPosts && sortedPosts.length > 0 && (
          <PostGrelha artigos={sortedPosts} key='yw73wlisjdf8373' tit='P' />
          )}

      </div>
    </React.Fragment>
  )
}