import React, {useState, useEffect } from "react";
import PostCompleteArt from "../components/PostCompleteArt";
import PostLista from "../components/PostLista";
import Bdexecute from "../components/Bdexecute";
import { useParams } from "react-router-dom";
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Menu from "../components/Menu";


export default function App() {
  let params = useParams();
  let [posts, setPosts] = useState([]);

  useEffect(() => {
    const loadPosts = async () => {
      let results;

      if (params.id) {
        results = await Bdexecute("GET", `api/posts/${params.id}`);
      }
      setPosts(results);
      if(results.title) {
      document.title = results.title;
      }
    }
  
    loadPosts();
  }, [params]);


  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

  let postsatuais;

  if (Array.isArray(posts)) {
      postsatuais = posts.filter((post) => post.datapublicacao < formattedDate);
  }
  
  let titulo = posts.title ? posts.title : params.id;
  titulo = titulo === "inqueritos" ? "Inquéritos" : titulo;
  titulo = titulo === "parcerias" ? "Parcerias" : titulo;
  

  return (
    <React.Fragment>

    <Menu />

    <div className="container-cinza">
      <div className="container">

      <Breadcrumb
      separator=">"
        items={[
          {
            title: <a href="/" alt={`Ligação para a página principal`}>Início</a>,
          },
          {
            title: titulo,
          },
        ]}
      />
      </div>
      </div>

      <div className="container">
      { (titulo === 'Inquéritos' || titulo === 'Parcerias') && (
        <div class="areaboxtitulop esquerda"><h1 className="h1style">{titulo}</h1></div>
      )}

      <div id="seccaoprincipal">


      {Array.isArray(postsatuais) && posts.length > 1 ? (
        postsatuais.map((artigo) => (
          <PostLista {...artigo} key={artigo._id} tp={params.id} id="u" id2="u" id3="u" id4={artigo._id} />
        ))
      ) : (

        Array.isArray(posts) ? (

          posts[0] && posts[0].datapublicacao < formattedDate ? (
            <PostCompleteArt {...posts[0]} tp={params.id} key={posts[0]._id} />
          ) : (
            <i></i>
          )

        ) : (

          posts && posts.datapublicacao < formattedDate ? (
            <PostCompleteArt {...posts} tp={params.id} key={posts._id} />
          ) : (
            <i></i>
          )

        )

      )}
      </div>

      <p className="center"><Link className='buttonSecundario' to="#" onClick={() => window.history.back()}>VOLTAR</Link></p>

      </div>
    </React.Fragment>
  )
}