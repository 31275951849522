
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Bdexecute from "./Bdexecute";
import { Select, Option } from "@leafygreen-ui/select";
import Icon from "@leafygreen-ui/icon";

const MenuPrincipalFiltro = (props) => {

  const [itensmenu, setItensmenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(props.id);
  const [selectedSubmenu, setSelectedSubmenu] = useState(props.id2);
  const [selectedSubsubmenu, setSelectedSubsubmenu] = useState(props.id3);
  const navigate = useNavigate();
  
  useEffect(() => {
    const loadPosts2 = async () => {
      try {
        let results;
        let results1;
        results1 = await Bdexecute("GET", `api/struct/`);
        results = results1.filter(item => item.principal !== "1");
        if (Array.isArray(results)) {
          setItensmenu(results);
        } else {
          console.error("Dados inválidos da API");
        }
      } catch (error) {
        console.error("Erro ao carregar dados da API", error);
      }
    };

    loadPosts2();
  }, []);



const handleMenuChange = (value) => {
  if(value) {
    setSelectedMenu(value);
    setSelectedSubmenu(null);
    setSelectedSubsubmenu(null);
    navigate(`/pag/${value}`);
  }
};

const handleSubmenuChange = (value) => {
  if(value) {
    setSelectedSubmenu(value);
    setSelectedSubsubmenu(null);
  }
};

const handleSubsubmenuChange = (value) => {
  if(value) {
    setSelectedSubsubmenu(value);
  } else {
    navigate(`/pag/${props.id}/${props.id2}`);
  }
};

useEffect(() => {
  if (!selectedSubmenu) { return }
  if(selectedSubmenu) {
    navigate(`/pag/${props.id}/${selectedSubmenu}`);
  }
}, [selectedSubmenu, props.id,navigate]);

useEffect(() => {
  if (!selectedSubsubmenu) { return }
  if(selectedSubsubmenu) {
    navigate(`/pag/${props.id}/${props.id2}/${selectedSubsubmenu}`);
  }
}, [selectedSubsubmenu, props.id, props.id2,navigate]);

function renderSubsubmenuOptions() {
  const selectedMenuData = itensmenu.find((opt) => opt._id === selectedMenu) || {};
  const selectedSubmenuData = (selectedMenuData.submenu || []).sort((a, b) => a.ordem - b.ordem);
  const selectedSubsubmenuData = (selectedSubmenuData.find((sub) => sub._id === selectedSubmenu)?.submenu || []).sort((a, b) => a.ordem - b.ordem);

  if (!selectedSubsubmenu && selectedSubsubmenuData && selectedSubsubmenuData.length === 1) {
    setSelectedSubsubmenu(selectedSubsubmenuData[0]._id);
  } 


  return selectedSubsubmenuData.map((opt) => (
    <Option key={opt._id} value={opt._id} label={opt.menu}>
      {opt.menu}
    </Option>
  ));
  
}

useEffect(() => {
  if (!selectedSubsubmenu) { return }
  if(selectedSubsubmenu) {
    navigate(`/pag/${props.id}/${props.id2}/${selectedSubsubmenu}`);
  }
}, [selectedSubsubmenu, props.id, props.id2,navigate]);


function renderSubmenuOptions() {
  const selectedMenuData = itensmenu.find((opt) => opt._id === selectedMenu) || {};
  const submenuData = (selectedMenuData.submenu || []).sort((a, b) => a.ordem - b.ordem);

  return submenuData.map((opt) => (
    <Option key={opt._id} value={opt._id} label={opt.menu}>
      {opt.menu}
    </Option>
  ));
}

const voltarPagina = () => {
  window.history.back();
};


  return <div><div  className='container-cinzaescuro'>
    <div className='container'>
    <div className="filtro-container">
          {<span tabIndex={0} onClick={voltarPagina} style={{ cursor: 'pointer' }}><Icon glyph="ChevronLeft" aria-label="Ir pra a a página anterior" size="xlarge" className="svg-icon"  /></span>}
          {<a href="/"><Icon glyph="Home" aria-label="Ir pra a a página principal" size="xlarge" className="svg-icon"   /></a>}

          <div className="filtro-item" aria-label="Selecione a área">
          <Select 
              align="top"
              placeholder="Selecione"
              baseFontSize={16}
              darkMode={false}
              size="default"
              description=""
              justify="start"
              label={<span className="filtro-tit">ÁREA</span>}
              name="area"
              readOnly={false}
              adjustOnMutation={true}
              onChange={(value) => handleMenuChange(value)}
              value={selectedMenu}
              errorMessage="Escolha uma opção de Área"
              className="custom-select"
            >
            {itensmenu.sort((a, b) => a.ordem - b.ordem).map((opt) => (
              <Option key={opt._id} value={opt._id} label={opt.menu}>
                {opt.menu}
              </Option>
            ))}

            </Select>
            </div>

            <div className="filtro-item" aria-label="Selecione o produto">
            <Select 
              align="top"
              placeholder="Selecione"
              baseFontSize={16}
              darkMode={false}
              adjustOnMutation={true}
              size="default"
              description=""
              justify="start"
              label={<span className="filtro-tit">PRODUTO</span>}
              name="area"
              readOnly={false}
              onChange={(value) => handleSubmenuChange(value)}
              value={selectedSubmenu}
              errorMessage="Escolha uma opção de Produto"
              state={selectedSubmenu ? 'none' : 'error'}
              className="custom-select"
            >
              {renderSubmenuOptions()}
            </Select>

            </div>

            <div className="filtro-item" aria-label="Selecione o tema">
          <Select 
              align="top"
              placeholder="Selecione"
              baseFontSize={16}
              darkMode={false}
              size="default"
              description=""
              justify="start"
              label={<span className="filtro-tit">TEMA</span>}
              name="tema"
              readOnly={false}
              adjustOnMutation={true}
              onChange={(value) => handleSubsubmenuChange(value)}
              value={selectedSubsubmenu}
              state={selectedSubmenu && !selectedSubsubmenu ? 'error' : 'none'}
              errorMessage="Escolha uma opção de Tema"
              className="custom-select"
            >
              {renderSubsubmenuOptions()}
            </Select>
    </div>
    </div>
  </div>

  
  </div></div>;
};
export default MenuPrincipalFiltro;