import { Input } from 'antd';
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

const { Search } = Input;

export default function Header(props) {
  const location = useLocation();
  const isInNavigation = location.pathname !== '/';

  let wrapperStyle = {
    display: "flex",
    flexWrap: "wrap",
    /* boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)", */
    marginLeft: "4%",
    width: "100%",
    verticalAlign: "top"
  }

  const onSearch = (value) => {
    const searchUrl = `/pesquisa/${value}`;
    window.location.replace(searchUrl);
  };

  return(
    <>
    <div className='MenuAcess'>
      <a href="#seccaoprincipal" aria-label="Saltar para o conteúdo principal">Conteúdo principal</a>
      <a href="/map" aria-label="Saltar para Mapa do site">Mapa do site</a>
      <a href="#campopesquisa" aria-label="Saltar para Pesquisa">Pesquisa</a>
      {isInNavigation && 
      <a href="/" aria-label="Saltar para a página inicial">Página inicial</a>
      }
    </div>
    <div style={wrapperStyle} className='alignTopo'>
          
          <div><a target="_blank" rel="noopener noreferrer" href="https://www.portugal.gov.pt/pt/"><img className='logoTopoRep' src="/ME_logo2024_horizontal.svg" alt="Abrir em nova janela a página do governo da República Portuguesa" title="Ir para página externa" /></a></div>
          <div>
          
          {isInNavigation ? (
            <a href="/">
              <img src="/logo_dgeec.png" className='logoTopo' alt="Ir para a página principal da Direção-Geral de Estatísticas da Educação e Ciência" />
            </a>
            ) : (
              <h1>
              <img src="/logo_dgeec.png" className='logoTopo' alt="Direção-Geral de Estatísticas da Educação e Ciência" />
              </h1>
            )
          }
            
            </div>
          
      <div className='div-pesquisa'>
        <label for="campopesquisa" aria-label="Pesquisa">Pesquisa</label><br />
        <Search id="campopesquisa" onSearch={onSearch} enterButton={<SearchOutlined aria-label="Pesquisar" />} style={{ width: 280, }} />
        </div>
        
    </div>
    </>
  )
}
