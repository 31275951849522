import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import PostSummary from "../components/PostSummary";
import PesquisaAgenda from "../components/PesquisaAgenda";
import Bdexecute from "../components/Bdexecute";

const containerStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridColumnGap: "20px",
};


export default function App() {
  let [posts, setPosts] = useState([]);
  let [agenda, setAgenda] = useState([]);
  const { valor } = useParams();


  useEffect(() => {
    const loadPosts = async () => {

      let results = await Bdexecute("GET", `api/posts/pesquisa/${valor}`);
      setPosts(results);

    }

    loadPosts();
  }, [valor]);


  useEffect(() => {
    const loadPosts = async () => {

        let results = await Bdexecute("GET", `api/agenda/pesquisa/${valor}`);
      setAgenda(results);

    }

    loadPosts();
  }, [valor]);

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();

    const postsatuais = posts.filter(post => post.datapublicacao < formattedDate);
    const artigosDestaque = postsatuais.filter(post => ['2', '3', '4', '5', '6'].includes(post.apresentacao));


  return (
    <React.Fragment>

    <div className="container"><p>&nbsp;</p>
    <h1 className="h1style">Resultados de pesquisa por: <span style={{ backgroundColor: '#DCEDC8' }}> {valor} </span></h1>
    <hr className="linha" />
      <div style={containerStyle}>
      
      
      <div id="seccaoprincipal">
        <h2>&nbsp;Artigos</h2>
        {artigosDestaque && artigosDestaque.length > 0 ? (
            artigosDestaque.map((post) => (
            <PostSummary {...post} key={post._id} />
            ))
        ) : (
            <i>Sem resultados</i>
        )}
        </div>


        <div>
        <h2>&nbsp;Atividades ou eventos</h2>
        {agenda && agenda.length > 0 ? (
            agenda.map((post) => (
            <PesquisaAgenda {...post} key={post._id} />
            ))
        ) : (
            <i>Sem resultados</i>
        )}
        </div>

      </div>
      </div>
    </React.Fragment>
  )
}