import React, {useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PostComplete from "../components/PostComplete";
import PostExpansor from "../components/PostExpansor";
import PostLista from "../components/PostLista";
import PostGrelha from "../components/PostGrelha";
import PostAgenda from "../components/PostAgenda";
import PostPublicacoes from "../components/PostPublicacoes";
import Bdexecute from "../components/Bdexecute";
import Menu from "../components/Menu";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";


export default function App() {
  let params = useParams();
  let [posts, setPosts] = useState([]);
  let [artigosAgenda, setartigosAgenda] = useState();


  useEffect(() => {
    const loadPosts = async () => {
      let resultsa = await Bdexecute("GET", `api/agenda/pub/${params.id}`)
      setartigosAgenda(resultsa);
    }
    loadPosts();
  }, [params.id]);



    useEffect(() => {
      const loadPosts = async () => {
        let results = await Bdexecute("GET", `api/posts/menu/${params.id}`)
        setPosts(results);
      }
      loadPosts();
    }, [params.id]);

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
  
    const postsatuais = posts.filter(post => post.datapublicacao < formattedDate);

    const artigosDestaque = postsatuais.filter(post => post.apresentacao === '1');
    const artigosCompletos = postsatuais.filter(post => post.apresentacao === '2');
    const artigosGrelha = postsatuais.filter(post => post.apresentacao === '3');
    const artigosLista = postsatuais.filter(post => post.apresentacao === '4');
    const artigosExpansor = postsatuais.filter(post => post.apresentacao === '5');
    const artigosPublicacoes = postsatuais.filter(post => post.apresentacao === '6');

    const images = [];

    let parametro = `${params.id}/${params.id2}/${params.id3}`;

    artigosDestaque.map(post => {
      let tituloLink = (
        <div>
          <p>
            <a className="galeria-desc" href={post.link ? post.link : `/art/${parametro}/${post._id}`}>
              {post.title}
            </a>
            </p>
          <a className="buttonSabermais" href={post.link ? post.link : `/art/${parametro}/${post._id}`}>
            SABER MAIS
          </a>
        </div>
      );
      images.push({
        original: post.imagem,
        thumbnail: post.imagem,
        originalTitle: post.title,
        originalAlt: post.title,
        description: tituloLink,
        originalHeight: 50,
        thumbnailWidth: 2,
        thumbnailHeight: 2,
        thumbnailClass: 'thumbnail-gallery',
      });
      return tituloLink;
    });

    const [autoplay, setAutoplay] = useState(true);
    const handleInteraction = () => {
      if (autoplay) {
        setAutoplay(false);
      }
    };


  return (
    <React.Fragment>

      <Menu id={params.id} id2={params.id2} id3={params.id3} />

      
      {images.length > 0 && (
      <ImageGallery 
      items={images} 
      autoPlay={autoplay}
      slideInterval={5000} // intervalo entre os slides em milissegundos (2 segundos neste exemplo)
      onClick={handleInteraction}
      showFullscreenButton={false}
      showPlayButton={false}
      showBullets={true}
      showThumbnails={false}
      stopPropagation={true}
      slideDuration={200}
    />
      )}
<br />
<div className="container-cinza">

  <div className="container">

      <div className="class-page">
        <div>

        <div className='container-bloco'>
      {artigosCompletos.map((artigo) => (
          <PostComplete {...artigo} key={artigo._id} id={params.id} id2={params.id2} id3={params.id3} />
        ))}
      </div>

      <div className='container-bloco'>
          {artigosGrelha && artigosGrelha.length > 0 && (
          <PostGrelha artigos={artigosGrelha} key='yw73wlisjdf8373'  tit='D' id={params.id} id2={params.id2} id3={params.id3} />
          )}
      </div>

      <div className='container-bloco'>
          {artigosPublicacoes && artigosPublicacoes.length > 0 && (
          <PostPublicacoes artigos={artigosPublicacoes} key='3ru28urijequ823jrij' id={params.id} id2={params.id2} id3={params.id3} />
          )}
      </div>

      <div className='container-bloco'>
      {artigosExpansor.map((artigo) => (
          <PostExpansor {...artigo} key={artigo._id} id={params.id} id2={params.id2} id3={params.id3} id4={artigo._id} />
        ))}
      </div>
      
      <div className='container-bloco'>
      {artigosLista.map((artigo) => (
          <PostLista {...artigo} key={artigo._id} id={params.id} id2={params.id2} id3={params.id3} id4={artigo._id} />
        ))}
      </div>


      </div>


      <div className="agenda-container-wrapper">

          {artigosAgenda && artigosAgenda.length > 0 && (
          <PostAgenda artigos={artigosAgenda} key='ywjtu3thh9jsd73' />
          )}
      

        </div>

      </div>
      </div>
      </div>
    </React.Fragment>
  )
}
