import Card from "@leafygreen-ui/card";
import { css } from "@leafygreen-ui/emotion";

const cardStyle = css`
  margin: 20px 2px 10px 2px;
  padding: 6px !important;
  border-radius: 0px !important;
  box-shadow: 0 4px 10px 4px rgba(255,255,255,1);
  background-color: #f2f2f2;

  &:hover {
  box-shadow: 0 4px 10px -4px rgba(0,30,43,0.3);
  }
`

export default function StructSummary(props) {
  console.log(props.principal)
  let linkpag = props.principal === '1' ? 'pagina' : 'pag';
  return (
    <div className="grelha-itemm">
    <div className="container-cinza">
    <Card className={cardStyle} key={props._id}>
    <p><a className="buttonPrincipal" href={`/${linkpag}/${props._id}`}> {props.menu} </a>

    {props && props.submenu &&
            <>
              {props.submenu.sort((a, b) => a.ordem - b.ordem).map(submenu => {
                return (
                  <>
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a className="buttonSecundario"  href={`/${linkpag}/${props._id}/${submenu._id}`}> {submenu.menu} </a>

                  {submenu.submenu &&
                        <>
                          {submenu.submenu.sort((a, b) => a.ordem - b.ordem).map(subsubmenu => {
                            return (
                              <>
                              <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a  href={`/${linkpag}/${props._id}/${submenu._id}/${subsubmenu._id}`}> {subsubmenu.menu} </a>
                              </>
                            )
                          })}
                        </>
                        }

                  </>
                )
              })}
            </>
            }

    </p>
    </Card>
    </div>
    </div>
  )
}