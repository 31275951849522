import React, {useState, useEffect} from "react";
import PostComplete from "../components/PostComplete";
import PostExpansor from "../components/PostExpansor";
import PostLista from "../components/PostLista";
import Bdexecute from "../components/Bdexecute";
import PostGrelha from "../components/PostGrelha";
import PostAgenda from "../components/PostAgenda";
import PostPublicacoes from "../components/PostPublicacoes";
import Card from "@leafygreen-ui/card";
import { css } from "@leafygreen-ui/emotion";
import FooterFirst from "../components/FooterFirst";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const cardStyle = css`
  width: 140px;
  height: 140px;
  font-size: 2em;
  border: 0px !important;
  padding: 120px 0px 20px 0px;
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50% !important;
  margin-left: auto;
  margin-right: auto;
  background-color: #f8f8f8;
`


export default function App() {
    let [artigos, setArtigos] = useState([]);
    let [secundarios, setSecundarios] = useState([]);
    let [artigosAgenda, setartigosAgenda] = useState();

    useEffect(() => {
      const loadPosts = async () => {
        let resultsa = await Bdexecute("GET", `api/agenda/pub`)
        setartigosAgenda(resultsa);
      }
    
      loadPosts();
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        const menuCompleto = await Bdexecute("GET", "api/struct");
        const menuPrincipal = menuCompleto.filter((post) => post.principal === "1");
        const menuSecundario = menuCompleto.filter((post) => post.principal === "0");
        setSecundarios(menuSecundario);
        
    
        const artigosPromises = menuPrincipal.map(async (post) =>
          await Bdexecute("GET", `api/posts/menu/${post._id}`)
        );
    
        const artigosResults = await Promise.all(artigosPromises);
        setArtigos(artigosResults);
      };
    
      fetchData();
    }, []);

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    const umaSemanaAtras = new Date(currentDate - 7 * 24 * 60 * 60 * 1000);
    const umaAnoaAtras = new Date(currentDate - 160 * 24 * 60 * 60 * 1000);
    const formattedDate1Semana = umaSemanaAtras.toISOString();
    const formattedDate1Ano = umaAnoaAtras.toISOString();

    const artigosatuais = artigos.map((artigosArray) => {
      if (artigosArray) {
        return artigosArray.filter(post => post.datapublicacao < formattedDate);
      } else {
        return [];
      }
    });



    const artigosDestaquetodos = artigosatuais.map((artigosArray) => {
      if (artigosArray) {
        return artigosArray.filter(post => post.apresentacao === '1');
      } else {
        return [];
      }
    });



    const artigosDestaque2 = artigosDestaquetodos
    .map(artigosArray => (Array.isArray(artigosArray) ? artigosArray.filter(post => post.dataartigo >= formattedDate1Semana) : []))
    .flat();

    const artigosDestaque3 = artigosDestaquetodos
    .map(artigosArray => (Array.isArray(artigosArray) ? artigosArray.filter(post => post.dataartigo >= formattedDate1Ano) : []))
    .flat();

  
  const artigosDestaque = [];
  const sourceArray = artigosDestaque2.length < 6 ? artigosDestaque3 : artigosDestaque2;

 
  while (artigosDestaque.length < 6 && sourceArray.length > 0) {
    artigosDestaque.push(sourceArray.shift());
  }
  


    const artigosCompletos = artigosatuais.map((artigosArray) => {
      if (artigosArray) {
        return artigosArray.filter(post => post.apresentacao === '2');
      } else {
        return [];
      }
    });

    const artigosGrelha = artigosatuais.map((artigosArray) => {
      if (artigosArray) {
        return artigosArray.filter(post => post.apresentacao === '3');
      } else {
        return [];
      }
    });


    const artigosLista = artigosatuais.map((artigosArray) => {
      if (artigosArray) {
        return artigosArray.filter(post => post.apresentacao === '4');
      } else {
        return [];
      }
    });

    const artigosExpansor = artigosatuais.map((artigosArray) => {
      if (artigosArray) {
        return artigosArray.filter(post => post.apresentacao === '5');
      } else {
        return [];
      }
    });

    const artigosPublicacoes = artigosatuais.map((artigosArray) => {
      if (artigosArray) {
        return artigosArray.filter(post => post.apresentacao === '6');
      } else {
        return [];
      }
    });




    const showBoxes = () => {
      const boxes = document.querySelectorAll('.areaboxchildren');
      boxes.forEach((box, index) => {
        setTimeout(() => {
          box.style.opacity = '1';
          box.style.visibility = 'visible';
        }, 200 * (index + 1));
      });
    };

  
    useEffect(() => {
      showBoxes();
    }, [secundarios]);



  
  return (
    <React.Fragment>
      
      <div className="container">
      <span className="direito"><a href="/destaques">Ver todos os destaques</a></span>

      </div>

      {artigosDestaque.length > 0 ? (
      <Carousel
      autoPlay={true}
      dynamicHeight={false}
      interval={3000}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      useKeyboardArrows={true}
      showArrows={true}
      showIndicators={false}
      stopOnHover={true}
      centerMode={false}
      labels={{leftArrow: 'Ir para o slide anteridor', rightArrow: 'Ir para o slide seguinte', item: 'Ir para o slide numero'}}
      >
        {artigosDestaque.flatMap((post) => (
        <div key={post._id} className="caroussel-gallery" style={{ backgroundImage: `url('${post.imagem ? post.imagem.replace(/ /g, "%20") : ""}')` }} >
          <p className="gal-tit-desc"> 
              {post.title}
          </p>
          <a className="buttonSabermais" area-label={`Saber mais sobre ${post.title}`} href={post.link ? post.link : `/artpub/${post._id}`}>
            SABER MAIS
          </a>
        </div>
        ))}
      </Carousel>
      ) : null}


      <div className="container">
    <br />
    <div id="seccaoprincipal">
    <h2 className="texto-cinza" >Consulte Estatísticas, Estudos e Base de Dados da Educação e Ciência</h2>
      <div className="areabox">
        {secundarios.map(secundario => {
          return (
            <a className="areaboxchildren" href={`/p/${secundario.menuurl}`}>
                <Card
                  className={`${cardStyle} card-hover-animation`}
                  style={{ backgroundImage: `url(${secundario.imagem})` }}
                >
            </Card>
            <div className="areaboxtitulo">
                <h3 className="texto-vermelho texto-normal">{secundario.menu.split(',')[0]}<br />{secundario.menu.split(',')[1]}</h3>
              </div>
            </a>
          );
        })}
      </div>
      </div>
</div>


<div className="container-cinza">

<div className="container">
   

      <div className="class-page">

      <div>

      

{/*       <Menu tit='1' ori='vertical' /> */}


      <div>
      {artigosCompletos.map((artigosArray) => {
          return artigosArray.map((artigo) => {
            return (
                <PostComplete {...artigo} key={artigo._id} />
            );
          });
        })}
      </div>

      <div>
        {artigosGrelha && artigosGrelha.length > 0 && artigosGrelha[0].length > 0 && (artigosGrelha.map((artigo) => {
            return (
                <PostGrelha artigos={artigo} key={artigo._id} tit='P' />
            );
          }))}
      </div>


      <div>
        {artigosPublicacoes && artigosPublicacoes.length > 0 && artigosPublicacoes[0].length > 0 && (artigosPublicacoes.map((artigo) => {
            return (
                <PostPublicacoes artigos={artigo} key={artigo._id} />
            );
          }))}
      </div>

      <div>
      {artigosExpansor.map((artigosArray) => {
          return artigosArray.map((artigo) => {
            return (
                <PostExpansor {...artigo} key={artigo._id} />
            );
          });
        })}
      </div>

      
      <div>
      {artigosLista.map((artigosArray) => {
          return artigosArray.map((artigo) => {
            return (
                <PostLista {...artigo} key={artigo._id} />
            );
          });
        })}
      </div>



      </div>

      <div className="agenda-container-wrapper">

          {artigosAgenda && artigosAgenda.length > 0 && (
          <PostAgenda artigos={artigosAgenda} key='ywjtu3thh9jsd73' />
          )}


        </div>

      </div>

      </div>
      </div>

      <FooterFirst />

    </React.Fragment>
  )
}
