import Header from "./Header";
import Footer from "./Footer";
import { css } from "@leafygreen-ui/emotion";
import { Outlet } from "react-router-dom";

const gridStyle = css`
  display: grid;
  grid-template:
    [header-start] "header header" 120px [header-end body-start]
    "body body" auto [body-end]
    "footer footer" auto [footer-end] / auto 1fr;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  min-width: 300px;
  margin: 0px;
`;

const headerStyle = css`
  grid-area: header;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  gap: 16px;
  padding: 10px 24px 12px 24px;
  /*box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);*/
  z-index: 1;
  /* background-color: #C8E6C9;
  background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(30,250,0,1) 49%, rgba(4,0,255,1) 100%);
  border-bottom: 40px solid #CFD8DC;
  #B71C1C - vermelho
  #B0BEC5 - cinza
  #C8E6C9 - verde claro
  verde escuro #006538
  #9bd0ba - verde synbad
  #37474F - cina escuro

  vermelho logo dgeec: #d41a28
  verde logo dgeec: #006538

  */
/*   background: -webkit-linear-gradient(to bottom, #C8E6C9 0%, #C8E6C9 60%, #FFFFFF 60%, #FFFFFF 70%, #CFD8DC 70%);
  background: linear-gradient(to bottom, #C8E6C9 0%, #C8E6C9 57%, #FFFFFF 57%, #FFFFFF 66%, #CFD8DC 66%); */

/*   background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 66%, #CFD8DC 66%); */

  
`;

const footerStyle = css`
  grid-area: footer;
  width: 100%;
  
`;


const mainStyle = css`
  grid-area: body;
  padding: 0px 0px 0px 0px;
  /* background-color: #f0f2f5; */
`;

export default function Layout(props) {
  return(
    <div className={gridStyle}>
      
      <header className={headerStyle}>
          <Header title=""/>
      </header>
      
      <main className={mainStyle}>
         <Outlet />
      </main>
      
      <footer className={footerStyle}>
          <Footer title=""/>
      </footer>
      
    </div>
  )
}