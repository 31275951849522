import React, { useState, useEffect } from "react";
import Card from "@leafygreen-ui/card";
import { css } from "@leafygreen-ui/emotion";
import Bdexecute from "./Bdexecute";
import PostSummaryAno from "./PostSummaryAno";
import ExcelReader from "../components/ExcelReader";
import ExpandableCard from '@leafygreen-ui/expandable-card';
import PostAgendaVerSummary from "../components/PostAgendaVerSummary";

const cardStylehistorico = css`
  margin-top: 10px;
  border-radius: 0px !important;
  text-align: justify;
  font-size: 1em !important;
  line-height: 1.3 !important;
  text-justify: inter-word;
  width: auto !important;
  overflow: auto;
  box-shadow: none !important;
  transition: transform 0.2s ease;

  &:hover {
    box-shadow: 0 4px 10px -4px rgba(0,30,43,0.3) !important;
  }
`


const cardStyle = css`
  padding: 0px !important;
  border-radius: 0px !important;
  border: 0px !important;
  font-size: 1em !important;
  line-height: 1.3 !important;
  text-align: justify;
  text-justify: inter-word;
  box-shadow: none !important;
  background-color: transparent !important;
`

const imgStyle = css`
max-width: 60vw;
display: block;
margin-left: auto;
margin-right: auto;
`

const cardStyleIndice = css`
  display: block;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px !important;
  min-height: 140px;
  box-shadow: none !important;
  padding: 10px;
`



export default function PostSummary(props) {
  let [postano, setPostano] = useState([]);
  let [documentos, setDocumentos] = useState([]);

  useEffect(() => {
    const loadPost2 = async () => {
      let results = await Bdexecute("GET", `api/postsano/${props._id}`);
      setPostano(results);
    }

    loadPost2();
  },[props]);


  useEffect(() => {
    if (props._id && props._id !== "") {
      const loadPosttabela = async () => {
        try {
          let results = await Bdexecute("GET", `api/poststable/${props._id}`);
          setDocumentos(results);
        } catch (error) {
          console.error(error);
        }
      };
  
      loadPosttabela();
    }
  }, [props._id]);

  function getMaiorData(array, dataAdicional) {
    if (!array || array.length === 0) {
      return dataAdicional || null;
    }
    if (array.length === 1) {
      const unicaData = array[0].datapublicacao;
      return unicaData > dataAdicional ? unicaData : dataAdicional;
    }
    const datas = array.map(post => post.datapublicacao);
    if (dataAdicional) {
      datas.push(dataAdicional);
    }
    const maiorData = datas.reduce((maior, data) => (data > maior ? data : maior), datas[0]);
    return maiorData;
  }

  useEffect(() => {
    const handleMessage = (event) => {
      if(props.incorporar && props.incorporar.length>0) {
      const urlObj = new URL(props.incorporar);
      const dominioBase = urlObj.origin;

      if (event.origin !== dominioBase) {
        return;
      }
      const meuIframe = document.getElementById('IDIframe');

      if (meuIframe) {
        meuIframe.style.height = event.data.height + 'px';
      }
    }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [props.incorporar]);

  const optionsdate = { day: 'numeric',  month: 'long', year: 'numeric' };
  
  const [showAll, setShowAll] = useState(false);
  
  const handleClick = () => {
    setShowAll(true);
  };

  const IndexOfTitles = ({ artigos }) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    const postsatuais = artigos.filter(post => post.datapublicacao < formattedDate);
    const itemsToShow = showAll ? postsatuais : postsatuais.slice(0, 10);

    return (
      <div>
        <ul aria-label="Índice">
          {itemsToShow.map((artigo, index) => (
            artigo.titleano && (
              <li className="liindex" key={index}>
                <a href={`#artigo-${artigo._id}`}>{artigo.titleano}</a>
              </li>
            )
          ))}
        {!showAll && artigos.length > 10 && (
          <li className="liindex">
          <a href="#ver-mais" alt="Abrir índice completo" onClick={handleClick}>Ver mais...</a>
          </li>
        )}
        </ul>
      </div>
    );
  };
  
  
  

  return (
    <React.Fragment>
      <article>
      <Card className={cardStyle}>

      {props.title || props.pdf || props.xls || props.ods ? (
      <>
      <h1 className="h1style">{props.title}
              {props.pdf || props.xls || props.ods ? (
                <>
                &nbsp;&nbsp;
                  {props.pdf && 
                  <a href={props.pdf} target="_blank" rel="noopener noreferrer"><img src="/pdf-icon.png" style={{ minWidth: '48px', maxWidth: '48px', margin: '6px', verticalAlign: 'text-bottom' }} alt={`Descarregar PDF sobre ${props.title}`} title="Descarregar PDF" /></a>
                  }
                  {props.xls && 
                  <a href={props.xls} target="_blank" rel="noopener noreferrer"><img src="/xls-icon.png" style={{ minWidth: '48px', maxWidth: '48px', margin: '6px', verticalAlign: 'text-bottom' }} alt={`Descarregar XLS sobre ${props.title}`} title="Descarregar XLS" /></a>
                  }
                  {props.ods && 
                  <a href={props.ods} target="_blank" rel="noopener noreferrer"><img src="/ods-icon.png" style={{ minWidth: '48px', maxWidth: '48px', margin: '6px', verticalAlign: 'text-bottom' }} alt={`Descarregar ODS sobre ${props.title}`} title="Descarregar ODS" /></a>
                  }
                </>
              ) : null}
      </h1>
      <br />
      </>
      ) : null}

      {props.agenda && 
            <>
            <div className="divArtigoAgenda" > <PostAgendaVerSummary key={props.agenda} id={props.agenda} /> </div>
            </>
      }

          {props.body && 
            <>
            <p className="paragrafoTexto" dangerouslySetInnerHTML={{ __html: props.body }} />
            </>
            }



            {documentos && documentos.length > 0 && 
            <>
            <table className="table-documentos">
              <caption>Tabela de documentos</caption>
              <thead>
              <tr>
                  <th scope="col">Documento</th>
                  <th scope="col">Ligação</th>
                </tr>
              </thead>
                <tbody>
                  {documentos.map(documento => (
                      <tr key={documento._id}>
                        <td width="70%">{documento.designacao && documento.designacao}</td>
                        <td>
                          {documento.imagem && 
                            <a href={documento.imagem} target="_blank" rel="noopener noreferrer">
                              <img src="/img-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px' }} alt={`Descarregar Imagem sobre ${documento.designacao}`} title="Descarregar Imagem" />
                            </a>
                          }

                         {documento.pdf && 
                          <a href={documento.pdf} target="_blank" rel="noopener noreferrer">
                            <img src="/pdf-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px' }} alt={`Descarregar PDF sobre ${documento.designacao}`} title="Descarregar PDF" />
                          </a>
                        }

                          {documento.xls && 
                          <a href={documento.xls} target="_blank" rel="noopener noreferrer">
                            <img src="/xls-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px' }} alt={`Descarregar XLS sobre ${documento.designacao}`} title="Descarregar XLS" />
                          </a>
                          }

                          {documento.ods && 
                          <a href={documento.ods} target="_blank" rel="noopener noreferrer">
                            <img src="/ods-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px' }} alt={`Descarregar ODS sobre ${documento.designacao}`} title="Descarregar ODS" />
                          </a>
                          }

                          {documento.dash && 
                          <a href={documento.dash} target="_blank" rel="noopener noreferrer">
                            <img src="/pbi-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px', }} alt={`Abrir dashboard sobre ${documento.designacao}`} title="Abrir Dashboard" />
                          </a>
                          }
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
              <br />
              </>
              }

            {props.imagem && (
                <p className="paragrafoTexto">
                  {props.dashboard ? (
                    <>
                      <a href={props.dashboard} target="_blank" rel="noopener noreferrer">
                        <img src={props.imagem} alt={`Abrir dashboard sobre ${props.title}`} title={`Ir para página externa`} className={imgStyle} />
                      </a>
                      </>
                  ) : props.link ? (
                    <>
                    <a href={props.link} target="_blank" rel="noopener noreferrer">
                      <img src={props.imagem} alt={`Imagem com ligação para ${props.title}`} title={`Ir para página externa`} className={imgStyle} />
                    </a>
                    </>
                    ) : (
                      <img src={props.imagem} alt={`Imagem ilustrativa de ${props.title}`} title={`Imagem relacionada com ${props.title}`} className={imgStyle} />
                  )}
                </p>
              )}

            {props.dashboard && (
                  <p className="center">
                  <a href={props.dashboard} target="_blank" rel="noopener noreferrer">
                    Clique aqui ou na imagem para abrir a aplicação
                  </a>
                  </p>
              )} 

            {props.incorporar && (
                <p>
                  <iframe id="IDIframe" src={props.incorporar} title={props.title} />
                </p>
              )} 

    <div>

          {postano && postano.length > 3 && (              
              <div className={cardStyleIndice}>
                <IndexOfTitles artigos={postano} />
              </div>
          )}

          {postano && postano.length > 0 && (              
            <>
              <PostSummaryAno artigos={postano} key='jhgf87jhrg983qy48hgoi' />
              <br />
            </>
          )}


          {props.bdxlsx && 
              <>
              <ExcelReader file={props.bdxlsx} filtro={props.BDfiltros} />
              <br />
              </>
          } 


          {props.historico && props.historico.length > 6 && (
            <ExpandableCard className={cardStylehistorico}
              description=""
              flagText=""
              title="Histórico"
            >
              <p className="paragrafoTexto" dangerouslySetInnerHTML={{ __html: props.historico }} /><br />
            </ExpandableCard>
          )}
      
    </div>
    <p className="paragrafoTexto"><span className="texto-cinza texto-data">Atualizado em {(new Date(getMaiorData(postano,props.datapublicacao))).toLocaleDateString('pt-PT', optionsdate)}</span></p>
      </Card>
      </article>
    </React.Fragment>
  )
}