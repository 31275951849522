
import Menu from "../components/Menu";

export default function App() {

	const htmlContent = `
	<h1>Declaração de Acessibilidade e Usabilidade</h1>
	<p>
													<span class="mr mr-e-intro"><span>A</span></span>
													<span class="mr mr-e-name"><span>Direção-Geral de Estatísticas da Educação e Ciência</span></span>
													compromete-se a disponibilizar
													<span class="mr mr-t-type"><span class="target-type">o sítio Web</span></span>
													<a href="https://www.dgeec.medu.pt" class="siteurl"><span class="mr mr-t-desc"><span class="target-name">dgeec.medu.pt</span></span></a>, 
													em conformidade com o Decreto-Lei n.º 83/2018, de 19 de
													outubro, que transpõe a Diretiva (UE) 2016/2102 do
													Parlamento Europeu e do Conselho, relativa à
													acessibilidade dos sítios Web e das aplicações móveis.
											</p>
	<h2 id="n1">I. Estado de conformidade</h2>
	<p class="capFL">
														<span class="target-type">O sítio Web</span>
														<a href="https://www.dgeec.medu.pt" class="siteurl"><span class="target-name">dgeec.medu.pt</span></a>
														d<span data-printfilter="lowerfirst" class="lowFL">a</span>
														<span>Direção-Geral de Estatísticas da Educação e Ciência</span> está
														<span class="mr mr-conformance-status"><span class="conformance-output">plenamente conforme</span></span>
														para com o Decreto-Lei n.º 83/2018 de 19 de outubro.
												</p>
	<h2 id="n2">
												II. Elaboração da presente declaração de acessibilidade e
												usabilidade
											</h2>
	<p>
												Esta declaração foi atualizada a
												<span class="mr mr-date"><span>2024-05-09</span></span>.
											</p>
	<p>
												De acordo com o artigo 9º do Decreto-Lei n.º 83/2018, as entidades
												devem adotar os procedimentos de monitorização a seguir
												apresentados. Os procedimentos A) e B) são obrigatórios. O
												procedimento C) é recomendado.
											</p>
	<p>
												<strong>A. Avaliações automáticas levadas a efeito</strong>
											</p>
	<div class="mr mr-automatic-summary"><ol><li>(2024-04-30). Relatório: <a href="https://observatorio.acessibilidade.gov.pt/directories/9/1028">Avaliação Automática</a><ul><li>Ferramenta utilizada: Access Monitor</li><li>Amostra: 80 páginas.</li><li>Principais resultados (sumário): No total das 80 páginas foi obtido um score de 9,6 na escala do AccessMonitor (1-10) tendo-se obtido os seguintes níveis de conformidade 2,5% das páginas passam a bateria de testes para o nível A, 0% passam os testes para o AA e 55% os testes para o AAA.</li></ul></li></ol></div>
	<p>
												<strong>B. Avaliações manuais levadas a efeito:</strong>
											</p>
	<div class="mr mr-manual-summary"><ol><li>(2024-05-07). Relatório: <a href="https://www.dgeec.medu.pt/api/ficheiros/663b5618ab52e973c2ea708d">Checklist 10 aspetos críticos de acessibilidade funcional</a><ul><li>Amostra: 80 páginas.</li><li>Principais resultados (heurísticas satisfeitas/total heurísticas aplicadas): 20/23</li></ul></li><li>(2024-05-07). Relatório: <a href="https://www.dgeec.medu.pt/api/ficheiros/663b56167cce3f632c39089d">Lista de verificação “Conteúdo”</a><ul><li>Amostra: 80 páginas.</li><li>Principais resultados (heurísticas satisfeitas/total heurísticas aplicadas): 15/17</li></ul></li><li>(2024-05-07). Relatório: <a href="https://www.dgeec.medu.pt/api/ficheiros/663b5615ab52e973c2ea708c">Lista de verificação “Transação”</a><ul><li>Amostra: 2 páginas.</li><li>Principais resultados (heurísticas satisfeitas/total heurísticas aplicadas): 9/10</li></ul></li></ol></div>
	<p>
													<strong>C. Testes de usabilidade com pessoas com
													deficiência:</strong>
											</p>
	<p class="capFL"><span class="target-type">O sítio Web</span> ainda não foi alvo de testes com
													utilizadores com deficiência.
											</p>
	<h2 id="n3">
												III. Contacto e solicitação de informação relativa
												<span class="target-type nType">ao sítio Web</span>
											</h2>
	<p>
												Para contactar, enviar sugestões, efetuar reclamações ou
												solicitar informação adicional relativamente aos conteúdos
												e/ou funcionalidades presentes n<span class="target-type">o sítio Web</span>
												d<span data-printfilter="lowerfirst" class="lowFL">a</span>
												<span>Direção-Geral de Estatísticas da Educação e Ciência</span>, utilize, por
												favor, os seguintes meios:
											</p>
	<div class="mr mr-contacts"><dl><dt>Correio eletrónico</dt><dd>fernando.brandao@dgeec.medu.pt</dd><dt>Telefone</dt><dd>213949377</dd></dl></div>
	<h2 id="n4">
												IV. Outras evidências<!-- / esforços para tornar os conteúdos
																mais acessíveis e mais usáveis-->
											</h2>
	<div><p>O sítio Web dgeec.medu.pt da Direção-Geral de Estatísticas da Educação e Ciência encontra-se certificado com o selo <span class="mr mr-seal">Prata</span> de usabilidade e acessibilidade. A afixação do selo Prata significa que o sítio Web dgeec.medu.pt:</p><ul><li>passa a lista de verificação “Conteúdos”</li><li>passa a bateria de testes de acessibilidade de uma ferramenta de validação automática comummente utilizada no mercado para a conformidade ‘AA’</li><li>passa a lista de verificação “Transação”</li><li>passa a lista de verificação “10 aspetos funcionais”</li></ul></div>
	<p></p>
	<h2 id="n5">
												V. Denúncia de situações de discriminação
											</h2>
	<p>
												De acordo com o n.º 1 do artigo 13.º do Decreto-Lei n.º 83/2018, de
												19 de outubro, sempre que uma pessoa com deficiência seja
												objeto de um tratamento menos favorável do que aquele que
												é, tenha sido ou venha a ser dado a outra pessoa em
												situação comparável, que consubstancie uma prática
												discriminatória contra pessoas com deficiência, prevista e
												punida nos termos do
												<a href="http://data.dre.pt/eli/lei/46/2006/08/28/p/dre/pt/html">artigo 4.º da Lei n.º 46/2006, de 28 de agosto</a>, pode, essa pessoa, apresentar queixa, de acordo com o
												disposto no
												<a href="https://data.dre.pt/eli/dec-lei/34/2007/02/15/p/dre/pt/html">Decreto-Lei n.º 34/2007, de 15 de fevereiro</a>.
											</p>
	<p>
												O Instituto Nacional para a Reabilitação (INR, I.P.),
												disponibiliza um
												<a href="http://www.inr.pt/resultados-de-pesquisa/-/journal_content/56/11309/45065?p_p_auth=wje2GjQi">formulário para denunciar situações de discriminação</a>, encaminhando as queixas apresentadas às entidades
												competentes. Anualmente, o INR, I.P. elabora um relatório
												anual sobre a aplicação da lei que proíbe e pune a
												discriminação em razão da deficiência e da existência de
												risco agravado de saúde (Lei n.o 46/2006, de 28 de
												agosto).
											</p>
	<hr aria-hidden="true">
	<p>
												A presente Declaração de Acessibilidade e Usabilidade foi
												criada com o auxílio do
												<a href="https://www.acessibilidade.gov.pt/gerador">Gerador WAI-Tools PT v1.5</a>, desenvolvido no âmbito do projeto
												<a href="https://w3.org/WAI/Tools">WAI-Tools</a>, de cujo
												consórcio a AMA é parte integrante. A Declaração foi
												concebida em conformidade com o Decreto-Lei n.º 83/2018, de 19 de
												outubro.
										</p>
  `;



  return (
    <>
	<Menu />

      <div className="container-cinza">
      <div className="container">
          <div className="grelha-container">
          <div className="container">

		  <div className="paragrafoTexto" dangerouslySetInnerHTML={{ __html: htmlContent }} />

		<p className="center"><a className='buttonSecundario' href="/" alt="Voltar" title="Voltar">VOLTAR</a></p>

		</div>
        </div>
      </div>
      </div>
    </>
  )
}