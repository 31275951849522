import React from 'react';
import { css } from "@leafygreen-ui/emotion";
import ExpandableCard from '@leafygreen-ui/expandable-card';
import Tag from "./Tag"

const cardStyle = css`
  margin-top: 10px;
  border-radius: 0px !important;
  text-align: justify;
  text-justify: inter-word;
  font-family: "Lato", Arial, sans-serif !important;
  width: auto !important;
  overflow: auto;
  box-shadow: none !important;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 10px -4px rgba(0,30,43,0.3) !important;
  }
`

const imgStyle = css`
  max-width: 200px;
  max-height: 200px;
  margin-right: 1em;
`

const containerStyle = css`
  display: flex;
  align-items: flex-start;
`;


export default function PostSummary(props) {
  //const [showMore, setShowMore] = useState(false);
const showMore = false;
/*   const toggleShowMore = () => {
    setShowMore(!showMore);
  }; */

  const paragraphs = props.body.split('</p>');

  const visibleParagraphs = showMore ? paragraphs : paragraphs.slice(0, 10);

  return (
    <React.Fragment>
      {props.title && props.title.length > 0 && (
        <ExpandableCard className={cardStyle}
          description=""
          flagText=""
          title={<span className="titulo-lista">{props.title}</span>}
        >
          {/* <p>{(new Date(props.date)).toLocaleDateString()}</p> */}
          {props.tags!=='' && (
          <Tag valor={props.tags} />
          )}
          
          <div className={containerStyle}>
          {props.imagem && (
            <p>
              <img src={props.imagem} alt={props.title} className={imgStyle} />
            </p>
          )}
          <div>
          {visibleParagraphs.map((paragraph, index) => (
            <p
              key={index}
              dangerouslySetInnerHTML={{
                __html: paragraph + '</p>'
              }}
            />
          ))}

          {paragraphs.length > 10 && !showMore && (
            <><p>...</p>
            <a className='buttonPrincipal' href={`/art/${props.id}/${props.id2}/${props.id3}/${props._id}`}>VER MAIS</a>
            <p>&nbsp;</p>
            </>
          )}
          </div>

        </div>

        </ExpandableCard>
      )}
    </React.Fragment>
  )
}