import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { LinkedinOutlined, InstagramOutlined, FacebookOutlined, UpOutlined, LeftOutlined, YoutubeOutlined, CopyOutlined } from '@ant-design/icons';
import { QRCode, Space, message } from 'antd';
import { css } from "@leafygreen-ui/emotion";
import Bdexecute from "../components/Bdexecute";
import MenuLista from "../components/MenuLista";

const footerStyle = css`
display: flex;
flex-wrap: wrap;
width: 100%;
padding: 10px 5% 10px 5%;
color: #ECEFF1;
background-color: #37474F;
/*background: linear-gradient(180deg,#FFFFFF 0%, #C8E6C9 30%, #DCEDC8 100%);*/
/* background: linear-gradient(155deg,#C8E6C9 0%, #cae2ce 40%, #dfd4c0 60%, #aa9a9b 70%, #9b473f 100%); */
/* background: linear-gradient(155deg,#C8E6C9 0%, #DCEDC8 40%, #C8E6C9 90%, #E8F5E9 100%); */

@media (max-width: 768px) {
  display: inline-block;
}
`;

const logoStyle = css`
width: 50%;
max-width: 160px;
`;



export default function Footer(props) {
  const location = useLocation();
  const inputRef = useRef(null);

  const currentUrl = window.location.origin + location.pathname;

  const [text, setText] = useState('https://www.dgeec.medu.pt/');
  const [shortlink, setShortlink] = useState('');

  let [artigosrodape, setArtigosrodape] = useState([]);

  useEffect(() => {
    const loadPosts = async () => {
      let resultsa = await Bdexecute("GET", `api/posts/rodape`)
      setArtigosrodape(resultsa);
    }
    loadPosts();
  }, []);

  useEffect(() => {
    setText(window.location.href);
  }, []);


  useEffect(() => {
    const loadPostss = async () => {
      const data = { currentUrl };
      let resultsas = await Bdexecute("POST", `api/shortlink`, data);
      setShortlink(resultsas.shortlink);
    }
    if (!currentUrl.includes('/l/') && !currentUrl.includes('/np4/') && !currentUrl.includes('/pesquisa/')) {
      loadPostss();
    }
  }, [currentUrl]);
  
  const shortUrl = window.location.origin + `/l/` + shortlink;

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

  const postsatuais = artigosrodape.filter(post => post.datapublicacao < formattedDate);

  let [artigosparcerias, setArtigosparcerias] = useState([]);

  useEffect(() => {
    const loadPostspa = async () => {
      let resultspa = await Bdexecute("GET", `api/posts/parcerias`)
      if(resultspa)  {
      setArtigosparcerias(resultspa);
      }
    }
    loadPostspa();
  }, []);

  const artigosparceriasf = artigosparcerias.filter(post => post.datapublicacao < formattedDate);

  document.addEventListener('DOMContentLoaded', function() {
    const btnTopo = document.getElementById('btn-topo');
    const btnVoltar = document.getElementById('btn-voltar');
  
    window.addEventListener('scroll', function() {
      if (window.scrollY < 640) {
        btnTopo.classList.add('hidden');
        btnVoltar.classList.add('hidden');
      } else {
        btnTopo.classList.remove('hidden');
        btnVoltar.classList.remove('hidden');
      }
    });
  
    btnTopo.addEventListener('click', function() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  
    btnVoltar.addEventListener('click', function() {
      window.history.back();
    });
  });
  
  const handleClick = () => {
    inputRef.current.value = shortUrl;
    inputRef.current.select();
    document.execCommand('copy');
    message.success('Link curto copiado para a área de transferência');
  };

  return(
    <>
    <div className={footerStyle}>
          <div className='div-footer'>
          <img src="/logo_dgeec_t.png" alt="Logotipo da DGEEC representando o acrónimo de Direção-Geral de Estatísticas da Educação e Ciência" className={logoStyle} />
          <ul>
            {artigosparceriasf && artigosparceriasf.length > 0 && artigosparceriasf.map((artigo) => {
              return (
                <>
                  {artigo.link ? (
                    <li key={artigo._id} className='texto-rodape'>
                    <a target="_blank" rel="noopener noreferrer" href={artigo.link}>
                      {artigo.title}
                    </a>
                    </li>
                  ) : (
                    <li key={artigo._id} className='texto-rodape'>
                    <a href={`/artpub/${artigo._id}`}>
                      {artigo.title}
                    </a>
                    </li>
                  )}
                </>
              );
            })}
            <li key="hdfsufyu3wufhf8982" className='texto-rodape'>
            <a href={`/acessibilidade`}>
              Acessibilidade
            </a>
            </li>
            <li className='texto-rodape'>
            <a target="_blank" rel="noopener noreferrer" href="https://selo.usabilidade.gov.pt">
              <img style={{ width: '160px' }} alt="Selo Prata de Usabilidade e Acessibilidade"
                    src="/prata-horizontal.svg" />
            </a>
            </li>
            </ul>
    </div>

    <div className='div-footer center'>
          <ul className='esquerda'>
        <MenuLista key="jd7g3jsfsf4t4hjhuo86thsft8j" />
        </ul>
        </div>

        <div className='div-footer center'>
          <ul className='esquerda'>
            {postsatuais && postsatuais.length > 0 && postsatuais.map((artigo) => {
              const linkurl = artigo.link ? artigo.link : `/artpub/${artigo._id}`;
              return (
                <li key={artigo._id} className='texto-rodape'>
                  <a href={linkurl}>{artigo.title}</a>
                </li>
              );
            })}
            <li className='texto-rodape'><a href="/pedidodados">Pedido personalizado de dados/informação</a></li>
            <li className='texto-rodape'><a href="/faleconnosco">Fale connosco</a></li>
            <li className='texto-rodape'><a href="/map">Mapa do site</a></li>
            <li className='texto-rodape'>
            <a target="_blank" rel="noopener noreferrer" href="https://www.livroreclamacoes.pt/Pedido/ElogioSugestao">
              <img alt="Livro de elogio ou sugestão online"
                    src="/livro_elogios.webp" />
            </a>&nbsp;
            <a target="_blank" rel="noopener noreferrer" href="https://www.livroreclamacoes.pt/Pedido/Reclamacao">
              <img alt="Livro de reclamações online"
                    src="/livro_reclamacoes.webp" />
            </a>
            </li>
            </ul>
        </div>




        <div id="footer-social" className='div-footer direita'>
        

        <div><p id="footer-social2" className='texto-rodape texto-branco direita'>Siga-nos em:</p></div>
          <Space>
          <a target="_blank" alt="Ir para a página da DGEEC no Linkedin" title="Ir para página externa" rel="noopener noreferrer" href="https://pt.linkedin.com/company/dgeec">
            <LinkedinOutlined className="social-icon" />
          </a>
          <a target="_blank" rel="noopener noreferrer" alt="Ir para a página da DGEEC no Youtube" title="Ir para página externa" href="https://www.youtube.com/@DGEEC_PT">
            <YoutubeOutlined className="social-icon" />
          </a>
          <a target="_blank" rel="noopener noreferrer" alt="Ir para a página da DGEEC no Instagram" title="Ir para página externa" href="https://www.instagram.com/dgeec_pt/">
            <InstagramOutlined className="social-icon" />
          </a>
          <a target="_blank" rel="noopener noreferrer" alt="Ir para a página da DGEEC no Facebook" title="Ir para página externa" href="https://www.facebook.com/dgeec.pt">
            <FacebookOutlined className="social-icon" />
          </a>
        </Space>
        <p className='texto-rodape texto-branco direita'>QR code: link para esta página&nbsp;
        {!currentUrl.includes('/l/') && !currentUrl.includes('/np4/') && !currentUrl.includes('/pesquisa/') && (
          <span role="img" aria-label="Copie o link curto para esta página" onClick={handleClick}>
            <CopyOutlined className="social-icon" />
            </span>
        )}
        </p>
        <Space direction="vertical" className='div-qr' align="center">
        <span role="img" aria-label="QR code: link para esta página">
          <QRCode value={text || '-'} color='#fff' size='140' errorLevel='M' />
          </span>
          {!currentUrl.includes('/l/') && !currentUrl.includes('/np4/') && !currentUrl.includes('/pesquisa/') && (
          <input 
          ref={inputRef}
          aria-label="Link curto para esta página"
          value={shortUrl}
          style={{ position: 'absolute', left: '-9999px' }}
          />
          )}
        </Space>
        <br /><br />


        </div>
    </div>
    <div id="btn-voltar" className="hidden"><LeftOutlined /></div>
    <div id="btn-topo" className='hidden'><UpOutlined alt="Ir para o topo" className='btn-topo-icon' /></div>
    </>
  )
}
