import React, { useState, useEffect, useCallback } from 'react';
import TextInput from '@leafygreen-ui/text-input';
import TextArea from "@leafygreen-ui/text-area";
import Toast from "@leafygreen-ui/toast";
import Checkbox from "@leafygreen-ui/checkbox";
import emailjs from "emailjs-com";
import Bdexecute from "../components/Bdexecute";
import { Link } from 'react-router-dom';
import ConfirmationModal from "@leafygreen-ui/confirmation-modal";
import { H3 } from "@leafygreen-ui/typography";
import { Alert, Space } from 'antd';
import Menu from "../components/Menu";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';


const USER_ID = "y_lO8T8PfMwWa8_y1";
const TEMPLATE_ID = "template_60k6ysb";
const SERVICE_ID = "service_7r0k2yv";

function MeuFormulario() {
  const [toastOpen, setToastOpen] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let [numero, setNumero] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  const [mostrarAlerta1, setMostrarAlerta1] = useState(false)
  const [mostrarAlerta2, setMostrarAlerta2] = useState(false)
  const [mostrarAlerta3, setMostrarAlerta3] = useState(false)
  const [mostrarAlerta4, setMostrarAlerta4] = useState(false)
  const [mostrarAlerta5, setMostrarAlerta5] = useState(false)

  useEffect(() => {
    let isMounted = true;
    const loadPosts = async () => {
      try {
        let results = await Bdexecute("GET", "api/lastpedidodados");
        if (isMounted) {
          let n = results[0].numero;
          n++;
          if(results[0].numero>0) {
            setNumero(n);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  
    loadPosts();
    return () => {
      isMounted = false;
    };
  });

  async function setObjid2(id) {
    if(id.length>15) {
    await enviarEmail(dadosFormulario, basicosecundario, ensinosuperior, cienciatecnologia, inovacao, sociedadeinformacao, producaocientifica, outros, numero, id);
  }
}

  async function enviarEmail(dadosFormulario, basicosecundario, ensinosuperior, cienciatecnologia, inovacao, sociedadeinformacao, producaocientifica, outros, numero, objId) {
    
    let email_para = '';

    if (basicosecundario===true) { 
      email_para += 'deebs.pedidos@dgeec.medu.pt;'; 
    }
    if (ensinosuperior===true) { 
      email_para += 'dgeec.dees@dgeec.medu.pt;'; 
    }
    if (cienciatecnologia===true || inovacao===true || sociedadeinformacao===true || producaocientifica===true) { 
      email_para += 'dsectsi.ctpedidos@dgeec.medu.pt;'; 
    }
    if (outros===true) { 
      email_para += 'dgeec@dgeec.medu.pt;'; 
    }
    
    const templateParams = {
      pedido_nome: dadosFormulario.nome,
      pedido_email: dadosFormulario.email,
      pedido_telefone: dadosFormulario.telefone,
      pedido_descricao: dadosFormulario.descricao,
      pedido_motivo: dadosFormulario.motivo,
      pedido_basicosecundario: basicosecundario,
      pedido_ensinosuperior: ensinosuperior,
      pedido_cienciatecnologia: cienciatecnologia,
      pedido_inovacao: inovacao,
      pedido_sociedadeinformacao: sociedadeinformacao,
      pedido_producaocientifica: producaocientifica,
      pedido_outro: outros,
      pedido_numero: numero,
      pedido_id: objId,
      to_email: email_para,
    };
  
    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
      .then((response) => {
        console.log("Email enviado com sucesso!", response.status, response.text);
        setToastOpen(true);
        setTimeout(() => setToastOpen(false), 5000);
        /* setTimeout(() => navigate('/'), 6000); */
      })
      .catch((error) => {
        console.error("Erro ao enviar o email:", error);
      });
  }

  const [dadosFormulario, setDadosFormulario] = useState({
    nome: '',
    email: '',
    telefone: '',
    descricao: '',
    motivo: '',
  });

  const [basicosecundario, setBasicoSecundario] = useState(false);
  const [ensinosuperior, setEnsinosuperior] = useState(false);
  const [cienciatecnologia, setCienciatecnologia] = useState(false);
  const [inovacao, setInovacao] = useState(false);
  const [sociedadeinformacao, setSociedadeinformacao] = useState(false);
  const [producaocientifica, setProducaocientifica] = useState(false);
  const [outros, setOutros] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMostrarAlerta1(false);
    setMostrarAlerta2(false);
    setMostrarAlerta3(false);
    setMostrarAlerta4(false);
    setMostrarAlerta5(false);

    setDadosFormulario({
      ...dadosFormulario,
      [name]: value,
    });
  };

  const inserirbd = async () => {
    const data = { dadosFormulario, basicosecundario, ensinosuperior, cienciatecnologia, inovacao, sociedadeinformacao, producaocientifica, outros, numero };
    let result = await Bdexecute("POST", `api/inserirpedidodados`, data);
  
    if (result) {
      try {
        let results2 = await Bdexecute("GET", "api/lastpedidodados");
        await setObjid2(results2[0]._id);
      } catch (error) {
        console.error("Erro ao obter os últimos dados do pedido:", error);
      }
    }
  };
  
  const handleSubmitConfirm = async (e) => {
    e.preventDefault();
    
    try {
      setShowModal(false);
      if (captchaIsDone) {
        await inserirbd(dadosFormulario, basicosecundario, ensinosuperior, cienciatecnologia, inovacao, sociedadeinformacao, producaocientifica, outros, numero);
        setCaptchaIsDone(false);
      }
      setSubmitting(false);
    } catch (error) {
    } finally {
      //setSubmitting(false);
    }
    
  }

  const cancelarSubmit = () => {
    setShowModal(false);
    setSubmitting(false);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (submitting) return;


    if (!dadosFormulario.nome) {
      setMostrarAlerta1(true);
      const nomeInput = document.getElementById('nome');
      if (nomeInput) {
        nomeInput.focus();
      }
      return;
    }

    if (!dadosFormulario.email) {
      setMostrarAlerta2(true);
      const emailInput = document.getElementById('email');
      if (emailInput) {
        emailInput.focus();
      }
      return;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(dadosFormulario.email)) {
        setMostrarAlerta2(true);
        const emailInput = document.getElementById('email');
        if (emailInput) {
          emailInput.focus();
        }
        return;
      }
    }

    if(!basicosecundario && !ensinosuperior && !cienciatecnologia && !inovacao && !sociedadeinformacao && !producaocientifica && !outros) {
      setMostrarAlerta3(true);
      const basicosecundarioInput = document.getElementById('basicosecundarioch');
      if (basicosecundarioInput) {
        basicosecundarioInput.focus();
      }
      return;
    }

    if (!dadosFormulario.descricao) {
      setMostrarAlerta4(true);
      const descricaoInput = document.getElementById('descricao');
      if (descricaoInput) {
        descricaoInput.focus();
      }
      return;
    }

    if (!dadosFormulario.motivo) {
      setMostrarAlerta5(true);
      const motivoInput = document.getElementById('motivo');
      if (motivoInput) {
        motivoInput.focus();
      }
      return;
    }

    setSubmitting(true);
    setShowModal(true);


  };

  function emailformat(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }


  const ReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
  
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
  
      try {
        const token = await executeRecaptcha();
        // Enviar token ao backend para verificação
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/api/verify-recaptcha`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
  
        const data = await response.json();
  
        if (data.success) {
          setCaptchaIsDone(true);
          console.log('reCAPTCHA validado com sucesso.');
        } else {
          console.log('Falha na verificação do reCAPTCHA.');
        }
      } catch (error) {
        console.error('Error executing recaptcha:', error);
      }

    }, [executeRecaptcha]);
  
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
  
    return true;
  };
  

  return (
    <>

    <Menu />

    <div className="container-cinza">
      <div className='container' id="seccaoprincipal">
        <h1 className='h1style'>Pedido personalizado de dados/informação</h1>
        <div className='container'>
        <p className="paragrafoTexto">A consulta às áreas de atuação da DGEEC (Educação Pré-Escolar, Básico e Secundário – Ensino Superior – Ciência e Tecnologia) permite verificar toda a informação estatística disponibilizada pela DGEEC. Caso não encontre resposta às suas necessidades, pode efetuar um pedido de informação estatística personalizado preenchendo o formulário abaixo.</p>
        <p className="paragrafoTexto">Por favor, indique o seu endereço de correio eletrónico e, no caso de um pedido de informação estatística, explicite a informação que necessita, referindo, sempre que possível, o período temporal e a unidade geográfica a que se refere.</p>
        <p className="paragrafoTexto">O seu pedido será analisado e receberá uma resposta, no prazo máximo de 5 dias úteis. Caso a informação esteja sujeita a tarifação, receberá primeiro um orçamento prévio, elaborado com base no <a href="/artpub/657c356023f9758986a7d461" target="_blank" rel="noopener noreferrer">tarifário em vigor.</a> </p>
        <br />
        <div style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}>
        <form onSubmit={handleSubmit}>
        <div style={{ maxWidth: '600px'}}>
            <TextInput
              label="Nome (obrigatório)"
              baseFontSize={16}
              optional={true}
              description=""
              name="nome"
              id="nome"
              onChange={handleInputChange}
              value={dadosFormulario.nome}
              state={dadosFormulario.nome ? 'valid' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta1 && (
                <Alert message="Por favor, preencha o campo obrigatório Nome" type="error" />
              )}
            </Space>

            <TextInput
              label="Email (obrigatório)"
              baseFontSize={16}
              type="email"
              optional={true}
              description=""
              name="email"
              id="email"
              onChange={handleInputChange}
              value={dadosFormulario.email}
              state={dadosFormulario.email && emailformat(dadosFormulario.email) ? 'valid' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta2 && (
                <Alert message="Por favor, insira um email válido" type="error" />
              )}
            </Space>

            </div>
            <div style={{ maxWidth: '300px'}}>
            <TextInput
              label="Telefone"
              baseFontSize={16}
              type="number"
              optional={true}
              description=""
              name="telefone"
              onChange={handleInputChange}
              value={dadosFormulario.telefone}
            />
            </div>
            <p>Dados a que se respeita (selecione pelo menos uma opção da lista)</p>
            <Checkbox
              description=""
              id="basicosecundarioch"
              baseFontSize={16}
              label={<span style={{ fontSize: '16px' }}>Ensino básico e secundário</span>}
              onChange={e => setBasicoSecundario(e.target.checked)}
              checked={basicosecundario}
            />
            <Checkbox
              description=""
              baseFontSize={16}
              label={<span style={{ fontSize: '16px' }}>Ensino Superior</span>}
              onChange={e => setEnsinosuperior(e.target.checked)}
              checked={ensinosuperior}
            />
            <Checkbox
              description=""
              baseFontSize={16}
              label={<span style={{ fontSize: '16px' }}>Ciência e Tecnologia</span>}
              onChange={e => setCienciatecnologia(e.target.checked)}
              checked={cienciatecnologia}
            />
            <Checkbox
              description=""
              label={<span style={{ fontSize: '16px' }}>Inovação</span>}
              baseFontSize={16}
              onChange={e => setInovacao(e.target.checked)}
              checked={inovacao}
            />
            <Checkbox
              description=""
              label={<span style={{ fontSize: '16px' }}>Sociedade de Informação</span>}
              baseFontSize={16}
              onChange={e => setSociedadeinformacao(e.target.checked)}
              checked={sociedadeinformacao}
            />
            <Checkbox
              description=""
              label={<span style={{ fontSize: '16px' }}>Produção Científica</span>}
              baseFontSize={16}
              onChange={e => setProducaocientifica(e.target.checked)}
              checked={producaocientifica}
            />
            <Checkbox
              description=""
              label={<span style={{ fontSize: '16px' }}>Outros</span>}
              baseFontSize={16}
              onChange={e => setOutros(e.target.checked)}
              checked={outros}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta3 && (
                <Alert message="Por favor, selecione a opção a que se respeitam os dados" type="error" />
              )}
            </Space>

            <br />

            <TextArea
              label={<span style={{ fontSize: '16px' }}>Descrição pormenorizada do pedido (obrigatório)</span>}
              baseFontSize={16}
              name="descricao"
              id="descricao"
              description=""
              onChange={handleInputChange}
              rows="6"
              optional={true}
              value={dadosFormulario.descricao}
              state={dadosFormulario.descricao ? 'none' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta4 && (
                <Alert message="Por favor, preencha o campo obrigatório Descrição" type="error" />
              )}
            </Space>
<br />
            <TextArea
              label={<span style={{ fontSize: '16px' }}>Motivo/Objetivos (obrigatório)</span>}
             baseFontSize={16}
              name="motivo"
              id="motivo"
              description=""
              onChange={handleInputChange}
              rows="6"
              optional={true}
              value={dadosFormulario.motivo}
              state={dadosFormulario.motivo ? 'none' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta5 && (
                <Alert message="Por favor, preencha o campo obrigatório Motivos ou Objetivos" type="error" />
              )}
            </Space>

            <GoogleReCaptchaProvider reCaptchaKey="6LftQ74pAAAAANYLLaWGmpE63XJeOvoAjwoQb3L4">
            <ReCaptchaComponent />
          </GoogleReCaptchaProvider>

            <p className="paragrafoTexto">A informação pessoal que consta neste formulário está protegida de divulgação, sendo utilizada exclusivamente para efeitos de tratamento do seu pedido e para o envio de um questionário pós-serviço (de resposta facultativa). Este questionário tem como objetivo avaliar o serviço que prestamos e permitir-nos-á implementar ações que melhorem a qualidade dos nossos produtos e do nosso serviço.</p>
            <p className="paragrafoTexto">Para mais informação consulte a <a href="/artpub/657364565f39ee77721e9c09" target="_blank" rel="noopener noreferrer">Política de Privacidade</a> da DGEEC.</p>
            <br />
            <p className='center-button'>
            <Link className='buttonSecundario' to="#" onClick={() => window.history.back()}>VOLTAR</Link>

            {captchaIsDone ? (
                <button className='buttonEnviar' disabled={submitting} type="submit">
                  {submitting ? 'A ENVIAR...' : 'ENVIAR'}
                </button>
              ) : (
                <button className='buttonEnviarDisabled' disabled={true} type="submit">
                  ENVIAR
                </button>
              )}

            </p>
          </form>
          </div>

      <ConfirmationModal
        open={showModal}
        buttonText="Enviar"
        onConfirm={handleSubmitConfirm}
        onCancel={cancelarSubmit}
        variant="primary"
        title="Confirmar"
        >
              
              <H3>Tem a certeza que deseja enviar o seu pedido de dados com os dados registados no formulário?</H3>

      </ConfirmationModal>


          <Toast
            variant="success"
            title="Pedido personalizado de dados"
            body="Pedido registado com sucesso."
            open={toastOpen}
            close={() => setToastOpen(false)}
          />
        </div>
      </div>
    </div>
    </>
  );
}

export default MeuFormulario;

