import React, { useState, useEffect, useCallback } from 'react';
import TextInput from '@leafygreen-ui/text-input';
import TextArea from "@leafygreen-ui/text-area";
import Toast from "@leafygreen-ui/toast";
import emailjs from "emailjs-com";
import { Link } from 'react-router-dom';
import ConfirmationModal from "@leafygreen-ui/confirmation-modal";
import { H3 } from "@leafygreen-ui/typography";
import { Alert, Space } from 'antd';
import Menu from "../components/Menu";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const USER_ID = "y_lO8T8PfMwWa8_y1";
const TEMPLATE_ID = "template_ko9lc5e";
const SERVICE_ID = "service_7r0k2yv";


function MeuFormulario() {
  const [toastOpen, setToastOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  let [showModal, setShowModal] = useState(false);
  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  const [mostrarAlertaNome, setMostrarAlertaNome] = useState(false);
  const [mostrarAlertaEmail, setMostrarAlertaEmail] = useState(false);
  const [mostrarAlertaComentario, setMostrarAlertaComentario] = useState(false);

  function enviarEmail(dadosFormulario) {
    const templateParams = {
      subject: "Fale connosco",
      to_name: "",
      to_email: "comunicacao@dgeec.medu.pt",
      message: "Foi inserida uma nova mensagem no site DGEEC",
      linha1: "Nome: " + dadosFormulario.nome,
      linha2: "Email: " + dadosFormulario.email,
      linha3: "Comentário: ",
      linha4: dadosFormulario.comentarios
    };
  
    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
      .then((response) => {
        console.log("Email enviado com sucesso!", response.status, response.text);
        setToastOpen(true);
        setTimeout(() => setToastOpen(false), 5000);
        /* setTimeout(() => navigate('/'), 6000); */
      })
      .catch((error) => {
        console.error("Erro ao enviar o email:", error);
      });
  }

  const [dadosFormulario, setDadosFormulario] = useState({
    nome: '',
    email: '',
    comentarios: '',
  });


  const handleInputChange = (e) => {
    setMostrarAlertaNome(false);
    setMostrarAlertaEmail(false);
    setMostrarAlertaComentario(false);
    const { name, value } = e.target;
    setDadosFormulario({
      ...dadosFormulario,
      [name]: value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submitting) return;

    
    if (!dadosFormulario.nome) {
      setMostrarAlertaNome(true);
      const nomeInput = document.getElementById('nome');
      if (nomeInput) {
        nomeInput.focus();
      }
      return;
    }

    if (!dadosFormulario.email) {
      setMostrarAlertaEmail(true);
      const emailInput = document.getElementById('email');
      if (emailInput) {
        emailInput.focus();
      }
      return;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(dadosFormulario.email)) {
        setMostrarAlertaEmail(true);
        const emailInput = document.getElementById('email');
        if (emailInput) {
          emailInput.focus();
        }
        return;
      }
    }

    if (!dadosFormulario.comentarios) {
      setMostrarAlertaComentario(true);
      const comentarioInput = document.getElementById('comentarios');
      if (comentarioInput) {
        comentarioInput.focus();
      }
      return;
    }

    setSubmitting(true);
    setShowModal(true);

  };

  const handleSubmitConfirm = async (e) => {
    e.preventDefault();

    try {
      setShowModal(false);
      if (captchaIsDone) {
        await enviarEmail(dadosFormulario);
        setCaptchaIsDone(false);
      }
      setSubmitting(false);
      
    } catch (error) {
    } finally {
      //setSubmitting(false);
    }
  }

  function emailformat(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const cancelarSubmit = () => {
    setShowModal(false);
    setSubmitting(false);
  }


    const ReCaptchaComponent = () => {
      const { executeRecaptcha } = useGoogleReCaptcha();
    
      // Create an event handler so you can call the verification on button click event or form submit
      const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }
    
        try {
          const token = await executeRecaptcha();
          // Enviar token ao backend para verificação
          const baseUrl = process.env.REACT_APP_BASE_URL;
          const response = await fetch(`${baseUrl}/api/verify-recaptcha`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });
    
          const data = await response.json();
    
          if (data.success) {
            setCaptchaIsDone(true);
            console.log('reCAPTCHA validado com sucesso.');
          } else {
            console.log('Falha na verificação do reCAPTCHA.');
          }
        } catch (error) {
          console.error('Error executing recaptcha:', error);
        }

      }, [executeRecaptcha]);
    
      useEffect(() => {
        handleReCaptchaVerify();
      }, [handleReCaptchaVerify]);
    
      return true;
    };
    


  return (
    <>
    <Menu />
    <div className="container-cinza">
      <div className='container'>
        <h1 className='h1style'>Partilhe connosco a sua opinião</h1>
        <div className='container' id="seccaoprincipal">
          <p className="paragrafoTexto">Para solicitação de dados estatísticos consulte a página:<br /><a href="https://www.dgeec.medu.pt/pedidodados" alt="link para pedido de dados">https://www.dgeec.medu.pt/pedidodados</a></p>
          <br />
          <div style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}>
          <form onSubmit={handleSubmit}>
          <div style={{ maxWidth: '600px'}}>
            <TextInput
              label="Nome (obrigatório)"
              baseFontSize={16}
              optional={true}
              description=""
              name="nome"
              id="nome"
              onChange={handleInputChange}
              value={dadosFormulario.nome}
              state={dadosFormulario.nome ? 'valid' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlertaNome && (
                <Alert message="Por favor, preencha o campo obrigatório Nome" type="error" />
              )}
            </Space>


            <TextInput
              label="Email (obrigatório)"
              baseFontSize={16}
              type="email"
              optional={true} 
              description=""
              name="email"
              id="email"
              onChange={handleInputChange}
              value={dadosFormulario.email}
              state={dadosFormulario.email && emailformat(dadosFormulario.email) ? 'valid' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlertaEmail && (
                <Alert message="Por favor, insira uma email válido" type="error" />
              )}
            </Space>
            </div>
            <br />

            <TextArea
              label={<span style={{ fontSize: '16px' }}>Comentário (obrigatório)</span>}
              baseFontSize={16}
              name="comentarios"
              id="comentarios"
              description=""
              optional={true}
              onChange={handleInputChange}
              rows="6"
              value={dadosFormulario.comentarios}
              state={dadosFormulario.comentarios ? 'none' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlertaComentario && (
                <Alert message="Por favor, preencha o campo obrigatório Comentário" type="error" />
              )}
            </Space>

            <GoogleReCaptchaProvider reCaptchaKey="6LftQ74pAAAAANYLLaWGmpE63XJeOvoAjwoQb3L4">
            <ReCaptchaComponent />
          </GoogleReCaptchaProvider>

            <p className="paragrafoTexto">A informação pessoal que consta neste formulário está protegida de divulgação, sendo utilizada exclusivamente para efeitos do seu contacto.</p>
            <p className="paragrafoTexto">Para mais informação consulte a <a href="/artpub/657364565f39ee77721e9c09" target="_blank" rel="noopener noreferrer">Política de Privacidade</a> da DGEEC.</p>
            <br />
            <p className='center-button'>
            <Link className='buttonSecundario' to="#" onClick={() => window.history.back()}>VOLTAR</Link>
            {captchaIsDone ? (
                <button className='buttonEnviar' disabled={submitting} type="submit">
                  {submitting ? 'A ENVIAR...' : 'ENVIAR'}
                </button>
              ) : (
                <button className='buttonEnviarDisabled' disabled={true} type="submit">
                  ENVIAR
                </button>
              )}

            </p>
          </form>
          </div>


          <ConfirmationModal
        open={showModal}
        buttonText="Enviar"
        onConfirm={handleSubmitConfirm}
        onCancel={cancelarSubmit}
        variant="primary"
        title="Confirmar"
        >
              
              <H3>Tem a certeza que deseja enviar a sua opinião com os dados registados no formulário?</H3>

      </ConfirmationModal>

          <Toast
            variant="success"
            title="Fale connosco"
            body="Mensagem registada com sucesso."
            open={toastOpen}
            close={() => setToastOpen(false)}
          />


        </div>
      </div>
    </div>
    </>
  );
}

export default MeuFormulario;

