import { useEffect, useState } from 'react';
import { css } from "@leafygreen-ui/emotion";
import Bdexecute from "../components/Bdexecute";
import Icon from "@leafygreen-ui/icon";

const footerFirstStyle = css`
display: flex;
flex-wrap: wrap;
width: 100%;
border-top: 0px solid #CFD8DC;
padding: 24px;
margin-top: 0px;
text-align: center;
`;

const iconStyle = css`
  transition: transform 0.3s ease;
  color:#37474F;
  cursor: pointer;
  width: 30px;
  height: 30px;

  &:hover {
    color: #d41a28;
    transform: scale(1.5);
  }
`;
const diviconStyle = css`
margin-left: 20px;
margin-top: 30px;
`

export default function FooterFirst(props) {

  let [artigosportais, setArtigosportais] = useState([]);
  let [artigosinqueritos, setArtigosinqueritos] = useState([]);

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

  useEffect(() => {
    const loadPostsp = async () => {
      let resultsp = await Bdexecute("GET", `api/posts/portais`)
      if(resultsp)  {
      setArtigosportais(resultsp);
      }
    }
    loadPostsp();
  }, []);

  useEffect(() => {
    const loadPostsi = async () => {
      let resultsi = await Bdexecute("GET", `api/posts/inqueritos`)
      if(resultsi) {
      setArtigosinqueritos(resultsi);
      }
    }
    loadPostsi();
  }, []);



  const artigosportaisf = artigosportais.filter(post => post.datapublicacao < formattedDate  && post.imagem).slice(0, 4);
  const artigosinqueritosf = artigosinqueritos.filter(post => post.datapublicacao < formattedDate && post.imagem);

  const shuffledArray = artigosinqueritosf.sort(() => Math.random() - 0.5);
  const artigosAleatorios = shuffledArray.slice(0, 4);

  return(
    <>
        <div className="container">
        <h2 className='h1style texto-cinza'>Inquéritos</h2>
            <div className={footerFirstStyle}>
          {artigosAleatorios && artigosAleatorios.length > 0 && artigosAleatorios.map((artigo) => {

              return (
                <div className='div-footer-first-box' key={artigo._id}>
                  {artigo.link ? (
                    <a target="_blank" rel="noopener noreferrer" href={artigo.link} title="Ir para página externa">
                      <img src={artigo.imagem} className='imgfooterfirst' alt={artigo.title} />
                    </a>
                  ) : (
                    <a href={`/artpub/${artigo._id}`}>
                      <img src={artigo.imagem} className='imgfooterfirst' alt={artigo.title} />
                    </a>
                  )}
                </div>
              );
            })}
            
            
            <a className={diviconStyle} href='/artpub/inqueritos' aria-label="Link para Outros inquéritos">
            <span role="img" aria-label="Outros inquéritos">
              <Icon glyph="ChevronRight" className={iconStyle} />
              </span>
              </a>
              
              
            
            </div>
            </div>
            

            <div className="container-verde">
            <div className="container">
            <h2 className='h1style texto-cinza'>Observatórios</h2>

          <div className={footerFirstStyle}>

            {artigosportaisf && artigosportaisf.length > 0 && artigosportaisf.map((artigo) => {
              return (
                <div className='div-footer-first-box' key={artigo._id}>
                  {artigo.link ? (
                    <a target="_blank" rel="noopener noreferrer" href={artigo.link} title="Ir para página externa">
                      <img src={artigo.imagem} className='imgfooterfirst2' alt={artigo.title} title={artigo.title} />
                    </a>
                  ) : (
                    <a href={`/artpub/${artigo._id}`}>
                      <mg src={artigo.imagem} className='imgfooterfirst2' alt={artigo.title} title={artigo.title} />
                    </a>
                  )}
                </div>
              );
            })}           
            </div>
            </div>
            </div>


       
       </>
  )
}
