import React, { useState, useEffect } from "react";
import { css } from "@leafygreen-ui/emotion";
import Bdexecute from "./Bdexecute";

const cardStyle = css`
  display: block;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px !important;
  min-height: 140px;
  box-shadow: none !important;
  padding: 10px;

  &:hover {
    box-shadow: 0 4px 10px -4px rgba(0, 30, 43, 0.3) !important;
    background-color: #f8f8f8;
  }

  @media (max-width: 860px) {
    width: 90%;
  }
`


const DocumentTable = ({ artigoId }) => {
  const [documentos, setDocumentos] = useState([]);

  useEffect(() => {
    const loadPosttabela = async () => {
      try {
        let results = await Bdexecute("GET", `api/poststable/${artigoId}`);
        setDocumentos(results);
      } catch (error) {
        console.error(error);
      }
    };

    if (artigoId && artigoId !== "") {
      loadPosttabela();
    }
  }, [artigoId]);

  return (
    <>
      {documentos && documentos.length > 0 && 
            <div>
            <br />
        <table className="table-documentos-ano">
          <caption>Tabela suplemento de artigo</caption>
          <thead>
              <tr>
                  <th scope="col">Documento</th>
                  <th scope="col">Ligação</th>
                </tr>
              </thead>
            <tbody>
              {documentos && documentos.length > 0 && documentos.map(documento => (
                <tr key={documento._id}>
                <td width="70%">{documento.designacao && documento.designacao}</td>
                <td>
                  {documento.imagem && 
                    <a href={documento.imagem} target="_blank" rel="noopener noreferrer">
                      <img src="/img-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px', }} alt={`Descarregar Imagem sobre ${documento.designacao}`} title="Descarregar Imagem" />
                    </a>
                  }

                  {documento.pdf && 
                  <a href={documento.pdf} target="_blank" rel="noopener noreferrer">
                    <img src="/pdf-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px', }} alt={`Descarregar PDF sobre ${documento.designacao}`} title="Descarregar PDF" />
                  </a>
                }

                  {documento.xls && 
                  <a href={documento.xls} target="_blank" rel="noopener noreferrer">
                    <img src="/xls-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px', }} alt={`Descarregar XLS sobre ${documento.designacao}`} title="Descarregar XLS" />
                  </a>
                  }

                  {documento.ods && 
                  <a href={documento.ods} target="_blank" rel="noopener noreferrer">
                    <img src="/ods-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px', }} alt={`Descarregar ODS sobre ${documento.designacao}`} title="Descarregar ODS" />
                  </a>
                  }

                  {documento.dash && 
                  <a href={documento.dash} target="_blank" rel="noopener noreferrer">
                    <img src="/pbi-icon.png" style={{minWidth: '36px', maxWidth: '36px', margin: '4px', }} alt={`Abrir dashboard sobre ${documento.designacao}`} title="Abrir Dashboard" />
                  </a>
                  }
                </td>
              </tr>
              ))}
            </tbody>
          </table>
          </div>
          
        }
        </>
  );
};


export default function PostSummaryAno(props) {

  const optionsdate = { year: 'numeric' };
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

  function groupPostsByYear() {
    const groupedPosts = {};
  
    const postsatuais = props.artigos.filter(post => post.datapublicacao < formattedDate);

    const sortedPosts = postsatuais.sort((a, b) => new Date(b.dataartigo) - new Date(a.dataartigo));
  
    // Group posts by year
    sortedPosts.forEach((post) => {
      const year = new Date(post.dataartigo).getFullYear();
      if (!groupedPosts[year]) {
        groupedPosts[year] = [];
      }
      groupedPosts[year].push(post);
    });
  
    // Convert groupedPosts object to an array and sort by year in descending order
    const groupedPostsArray = Object.keys(groupedPosts)
      .map((year) => ({
        year: year,
        posts: groupedPosts[year],
      }))
      .sort((a, b) => b.year - a.year); // Sort in descending order
  
    return groupedPostsArray;
  }

  return (
<React.Fragment>
    {groupPostsByYear().map((yearPosts) => (
      <div key={yearPosts.year }>
        {yearPosts.posts.map((post) => (
          <>
              <div className={cardStyle} id={`artigo-${post._id}`}>

              <div className="grelha-item-ano-titulo">

              {post.imagem && 
              <div style={{ float: 'left', width:'40vw', height: '20vh', minWidth: '100px', minHeight: '50px', maxWidth: '200px', maxHeight: '100px', margin: '10px', backgroundSize: 'cover', backgroundImage: `url('${post.imagem ? post.imagem.replace(/ /g, "%20") : ""}')` }}></div>
              }

              <h2 className="grelha-item-data-titulo">{post.titleano}</h2>
              <div className="div-docs">
              {post.pdf && 
              <a href={post.pdf} target="_blank" rel="noopener noreferrer"><img src="/pdf-icon.png" style={{ minWidth: '48px', maxWidth: '48px', margin: '6px', }} alt={`Descarregar PDF sobre ${post.titleano}`} title="Descarregar PDF" /></a>
              }
              {post.xls && 
              <a href={post.xls} target="_blank" rel="noopener noreferrer"><img src="/xls-icon.png" style={{ minWidth: '48px', maxWidth: '48px', margin: '6px', }} alt={`Descarregar XLS sobre ${post.titleano}`} title="Descarregar XLS" /></a>
              }
              {post.ods && 
              <a href={post.ods} target="_blank" rel="noopener noreferrer"><img src="/ods-icon.png" style={{ minWidth: '48px', maxWidth: '48px', margin: '6px', }} alt={`Descarregar ODS sobre ${post.titleano}`} title="Descarregar ODS" /></a>
              }
              </div>
              </div>
            

              <p className="margem-esquerda" dangerouslySetInnerHTML={{ __html: post.body }} style={{ clear: 'both' }}/>

              <DocumentTable artigoId={post._id} />

              {post.incorporar && (
                <p>
                <iframe src={post.incorporar} width='100%' height='650px' title={post.titleano} />
                </p>
                )}

                <p className="grelha-item-data">Ano da edição: {(new Date(post.datapublicacao)).toLocaleDateString('pt-PT', optionsdate)}</p>
            </div>
            
                
            </>
        ))}
        </div>
    ))}
  
</React.Fragment>
  );
}