import React from "react";
import Badge from "@leafygreen-ui/badge";
import { css } from "@leafygreen-ui/emotion";

const badgeColors = ["lightgray", "darkgray", "red", "blue", "green", "yellow"];
const getBadgeColor = tag => {
  let tagId = tag.split("").map(char => char.charCodeAt(0)).reduce((s,a) => s + a, 0) % 6;
  return badgeColors[tagId];
}

const tagStyle = css`
  margin: 6px;
  padding: 14px;
  border-radius: 0px;
`;

export default function Tag({ valor = [] }) {
  const filteredValor = valor.filter((v) => v !== "");

  return filteredValor.map((v, i) => (
    <React.Fragment key={i}>
      <Badge className={tagStyle} variant={getBadgeColor(v)}>{v}</Badge>
    </React.Fragment>
  ));
}